import React from "react";
import styles from "./styles.module.css";

interface SelectOption {
  label: string;
  value: string;
  key: string;
}

interface Props {
  id?: string;
  value?: string | number | string[] | undefined;
  label?: string;
  placeholder?: string;
  password?: boolean;
  options: SelectOption[];
  onChange?: (value: string) => void;
}

const InputSelect: React.FC<Props> = (props) => {
  const { id, value, label, onChange, options, placeholder } = props;

  return (
    <div className={styles.inputContainer}>
      {label ? (
        <label className={styles.inputLabel} htmlFor={id}>
          {label}
        </label>
      ) : null}

      <select
        id={id}
        className={styles.input}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => onChange?.(e.target.value)}
        placeholder={placeholder}
      >
        {options.map((option) => (
          <option value={option.value} key={option.key}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default InputSelect;
