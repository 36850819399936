import React from "react";
import classnames from "classnames/bind";
import styles from "./styles.module.css";

const cx = classnames.bind(styles);

interface Props {
  open?: boolean;
  onClose?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Modal: React.FC<Props> = ({ children, onClose, open }) => {
  return (
    <div className={cx(["container", open ? "containerOpen" : "containerClosed"])} onClick={onClose}>
      <div className={cx(["wrapper", open ? "wrapperOpen" : "wrapperClosed"])}>{children}</div>
    </div>
  );
};

export default Modal;
