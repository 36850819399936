import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionLayout from "../../components/SectionLayout";
import SectionLoader from "../../components/SectionLoader";
import AuthHoc from "../../components/AuthHoc";
import { showPlan, updatePlan, createPlan, deletePlan, planDefaultState, validatePlanData } from "../../services/plans";
import { Plan } from "../../utils/types";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.css";
import InputText from "../../components/Input/Text";
import InputSelect from "../../components/Input/Select";
import { formatDate } from "../../utils/time";
import ConfirmDeleteModal from "../../components/ConfirmDelete";
import CustomError from "../../utils/CustomError";

interface Params {
  id: string;
}
export type Props = RouteComponentProps<Params>;

export interface State {
  status: "initialLoader" | "loading" | "loaded" | "success" | "failure";
  message: string;
  data: Plan;
  requiredFields: string[];
  modal: string;
  error: CustomError | null;
}

class PlanDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: "",
      status: "initialLoader",
      message: "",
      data: planDefaultState(null),
      requiredFields: [],
      error: null
    };
  }

  componentDidMount(): void {
    this.load();
  }

  load = async (): Promise<void> => {
    this.setState({
      status: this.state.status === "initialLoader" ? "initialLoader" : "loading"
    });

    try {
      if (this.props.match.params.id) {
        const data = await showPlan(this.props.match.params.id);
        this.setState({ status: "loaded", data });
      } else {
        this.setState({ status: "loaded", data: planDefaultState(null) });
      }
    } catch (error) {
      this.setState({
        status: "failure",
        message: "An unexpected error ocurred"
      });
    }
  };

  create = async (): Promise<void> => {
    this.setState({ status: "loading", message: "" });
    try {
      validatePlanData(this.state.data);
      const data = await createPlan(this.state.data);
      this.setState({ status: "loaded", data, message: "Plan Created!" }, () =>
        this.props.history.push(`/admin/plans/${data.id}`)
      );
    } catch (error) {
      this.setState({
        status: "failure",
        error: error
      });
    }
  };

  update = async (): Promise<void> => {
    this.setState({ status: "loading", message: "" });
    try {
      validatePlanData(this.state.data);
      const dataDoc = await updatePlan(this.state.data);
      this.setState({
        status: "loaded",
        message: "Plan Updated!",
        data: dataDoc
      });
    } catch (error) {
      this.setState({
        status: "failure",
        error
      });
    }
  };

  delete = async (): Promise<void> => {
    this.setState({ status: "loading", message: "" });
    try {
      await deletePlan(this.state.data.id);
      this.setState({ status: "loaded", message: "Plan Deleted!" }, () => this.props.history.push(`/admin/plans`));
    } catch (error) {
      this.setState({
        status: "failure",
        message: error.message
      });
    }
  };

  onChange = (change: Partial<Plan>): void => {
    this.setState((state) => ({ data: { ...state.data, ...change } }));
  };

  render(): JSX.Element {
    const { status, message, data, modal, error } = this.state;

    return (
      <SectionLayout label={status === "initialLoader" ? "Plans" : data.createdAt ? `Plan` : "New Plan"}>
        <ConfirmDeleteModal
          open={modal === "delete"}
          onClose={(): void => this.setState({ modal: "" })}
          onClick={this.delete}
        />

        {status === "initialLoader" ? (
          <SectionLoader />
        ) : (
          <>
            <div className={styles.container}>
              <div className={styles.row}>
                <InputText
                  id="id"
                  label={`Id: *`}
                  value={data.id}
                  disabled={!data.createdAt ? false : true}
                  onChange={(value): void => this.onChange({ id: value })}
                  error={error?.getError("id")}
                />
                <div />
                <div />
              </div>
              <div className={styles.row}>
                <InputText
                  id="bundleCode"
                  label={`Bundle code: *`}
                  value={data.bundleCode}
                  onChange={(value): void => this.onChange({ bundleCode: value })}
                  error={error?.getError("bundleCode")}
                />
                <InputText
                  id="months"
                  label={`Months: *`}
                  value={data.months}
                  onChange={(value): void => this.onChange({ months: value })}
                  error={error?.getError("months")}
                />
                <InputText
                  id="internationalTopUpCode"
                  label={`International top up code: ${data.type === "payg" ? "*" : ""}`}
                  value={data.internationalTopUpCode}
                  onChange={(value): void => this.onChange({ internationalTopUpCode: value })}
                  error={error?.getError("internationalTopUpCode")}
                />
              </div>
              <div className={styles.row}>
                <InputText
                  id="provider"
                  label={`Provider: *`}
                  value={data.provider}
                  onChange={(value): void => this.onChange({ provider: value })}
                  error={error?.getError("provider")}
                />
                <InputText
                  id="description"
                  label={`Description: *`}
                  value={data.description}
                  onChange={(value): void => this.onChange({ description: value })}
                  error={error?.getError("description")}
                />
                <InputText
                  id="monthlyPrice"
                  label={`Monthly price: *`}
                  value={data.monthlyPrice}
                  onChange={(value): void => this.onChange({ monthlyPrice: value })}
                  error={error?.getError("monthlyPrice")}
                />
              </div>
              <div className={styles.row}>
                <InputText
                  id="planAmount"
                  label={`Plan amount: *`}
                  value={data.planAmount}
                  onChange={(value): void => this.onChange({ planAmount: value })}
                  error={error?.getError("planAmount")}
                />
                <InputText
                  id="regulatoryFee"
                  label={`Regulatory fee: *`}
                  value={data.regulatoryFee}
                  onChange={(value): void => this.onChange({ regulatoryFee: value })}
                  error={error?.getError("regulatoryFee")}
                />
                <InputText
                  id="zipCode"
                  label={`Zip code: *`}
                  value={data.zipCode}
                  onChange={(value): void => this.onChange({ zipCode: value })}
                  error={error?.getError("zipCode")}
                />
              </div>
              <div className={styles.row}>
                <InputSelect
                  id="type"
                  label="Plan type:"
                  value={data.type}
                  onChange={(value): void => this.onChange({ type: value })}
                  options={[
                    {
                      key: "preloadedPlan",
                      label: "Instant Preloaded",
                      value: "preloadedPlan"
                    },
                    {
                      key: "payg",
                      label: "Regular Activation",
                      value: "payg"
                    }
                  ]}
                />
                <div>
                  <div className={styles.message_label}>
                    {status === "loading" ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      <div className={status === "failure" ? styles.messageRed : styles.message}>{message}</div>
                    )}
                  </div>
                </div>
                <div className={styles.buttons}>
                  <Link to={"/admin/plans"}>
                    <button className={styles.button}>Back</button>
                  </Link>
                  {!data.createdAt ? (
                    <button className={styles.button} onClick={this.create}>
                      Create
                    </button>
                  ) : (
                    <>
                      <button className={styles.button} onClick={this.update}>
                        Update
                      </button>
                      <button className={styles.delete_button} onClick={(): void => this.setState({ modal: "delete" })}>
                        Delete
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <br />

            {data.createdAt ? (
              <div className={styles.container}>
                <div className={styles.row_info}>
                  <p>Created by: {data.createdBy}</p>
                  <p>Created at: {formatDate(data.createdAt)}</p>
                  <p>Updated at: {data.updatedAt ? `${formatDate(data.updatedAt)}` : "----"}</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </SectionLayout>
    );
  }
}

export default AuthHoc(PlanDetail);
