import { get } from "lodash";

export function emailIsValid(email: string): boolean {
  return /^[\w-_.+]+@([\w-]+.)+[\w-]{2,4}$/.test(email);
}
export function checkOrderNumber(data: string): boolean {
  return /[0-9]{5}/.test(data);
}
export function checkICCID(data: string): boolean {
  return /[0-9]{19}/.test(data);
}

export function checkImei(data: string): boolean {
  return /[0-9]{15}/.test(data);
}

export function checkPuk(data: string): boolean {
  return data.length === 8;
}

export function getDataInFinalScroll(
  e: React.UIEvent<HTMLElement, UIEvent>,
  actualLength: number,
  lastLength: number
): boolean {
  const element = e.target as HTMLElement;
  return element.scrollHeight - element.scrollTop === element.clientHeight && actualLength !== lastLength;
}

export function validateCheckboxLink(e: React.MouseEvent<HTMLDivElement>): void {
  const tagName = get(e, "target.tagName", "");
  if (tagName === "DIV") {
    e.preventDefault();
  } else {
    e.stopPropagation();
  }
}
export function validateInputLink(e: React.MouseEvent<HTMLDivElement>): void {
  const tagName = get(e, "target.tagName", "");
  if (tagName === "SPAN") {
    e.preventDefault();
  }
}
export function validateICCIDLength(iccid: string): boolean {
  return iccid.length === 19;
}
