import React from "react";
import { Link } from "react-router-dom";
import { functions } from "../../utils/firebase";
import AuthHoc from "../../components/AuthHoc";
import styles from "./styles.module.css";
import SectionLayout from "../../components/SectionLayout";
import SectionLoader from "../../components/SectionLoader";
import { User } from "../../utils/types";
import ListItem from "./ListItem";

export interface State {
  status: "initialLoader" | "loading" | "loaded" | "failure";
  itemsLimit: number;
  lastItem: string;
  data: User[];
}

class Users extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      status: "initialLoader",
      itemsLimit: 20,
      lastItem: "",
      data: []
    };
  }
  componentDidMount(): void {
    this.load();
  }
  load = async (): Promise<void> => {
    try {
      const { status } = this.state;
      this.setState({
        status: status === "initialLoader" ? "initialLoader" : "loading"
      });
      const result = await functions?.httpsCallable("listUsers")();
      if (result && result.data) {
        this.setState({
          data: result.data,
          status: "loaded"
        });
      } else {
        this.setState({
          data: [],
          status: "loaded"
        });
      }
    } catch (error) {
      this.setState({ status: "failure" });
    }
  };
  render(): JSX.Element {
    const { status, data } = this.state;
    return (
      <SectionLayout label="Users">
        <div className={styles.list}>
          <div className={styles.header}>
            <Link to={`/admin/users/new`}>
              <button className={styles.button}>Add User</button>
            </Link>
          </div>
          <div className={styles.listHeader}>
            <div>Name</div>
            <div>Email</div>
            <div>Enabled</div>
          </div>
          {status === "initialLoader" ? (
            <SectionLoader />
          ) : (
            <>
              {data.map((item) => (
                <ListItem data={item} key={item.uid} />
              ))}
            </>
          )}
        </div>
      </SectionLayout>
    );
  }
}

export default AuthHoc(Users);
