import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          activationFormTitle: "SIM Card Activation Schedule",
          activationFormSubtitle: "Please fill this form to schedule your SIM card activation",
          nameInputLabel: "Your Name",
          nameInputEmptyError: "Please enter your name",
          emailInputLabel: "Your email address ",
          emailInputEmptyError: "Please enter your email address",
          emailInputInvalidError: "Please enter a valid email address",

          orderInputLabel: "Your order number",
          orderInputEmpyError: "Enter your order number",
          orderInputInvalidError: "Invalid order number",

          iccidInputLabel: "Sim card number (ICCID)",
          iccidCharacters: "19 characters",
          iccidInputEmptyError: "Please enter your sim card number (ICCID)",
          iccidInputInvalidError: "The sim card number (ICCID) is invalid",

          pukInputLabel: "PUK",
          pukInputInvalidError: "The PUK is invalid.",
          pukInputEmptyError: "Please enter your PUK",

          imeiInputLabel: "IMEI (without hyphens)",
          imeiCharacters: "15 characters",
          imeiInputEmptyError: "Please enter your IMEI witout hyphens",
          imeiInputInvalidError: "The IMEI is invalid. Please enter without hyphens",
          imeiHelpText:
            "To activate your SIM, we need the IMEI number of your mobile phone. You can find this in your mobile phone settings or after dialing *#06# in your mobile phone.",

          activationDateInputLabel: "Activation date",
          activationDateInputEmptyError: "Enter an activation date",
          returnDateInputLabel: "Last day of your travel",
          returnDateInputEmptyError: "Enter the last day of your travel",

          activateAnotherCardButtonLabel: "Activate another sim card",

          termsInputLabel: "Waiver of right of withdrawal",
          terms:
            "Herewith I waive the 14 days right of withdrawal as described in the Terms & Conditions and want beachsim.com to start the service already on the preferred date given above.",
          send: "Send",

          successUrl: "https://www.beachsim.com/en/activation-of-lycamobile-us-sim-card-success/",

          support: "Contact with support",
          supportUrl: "https://www.beachsim.com/en/contact/",

          imprint: "Imprint",
          imprintUrl: "https://www.beachsim.com/en/imprint/",

          success: "Success",
          successMessage:
            "You also will receive an email confirming your scheduling for activation. Please also check your spam folder.",
          alradyScheduleOn: "We noticed your card is already scheduled for activation on: ",
          reScheduleTo:
            "Since both dates are in the future, you are allowed to change activation. Click Continue if you are sure that you want to reschedule the activation of your sim card to the following next date: ",
          continue: "Continue",
          back: "Back",
          rescheduleWithFailure:
            "Your sim is currently in the activation process or has already been activated. We will contact you shortly by email",
          termsUnChecked: "Please accept terms and conditions",
          fillAllData: "Please complete all the required fields",
          confirmationTitle: "Confirm your information",
          confirmationMessage:
            "Dear customer, after submitting this form, you will receive a confirmation email within the next minutes. Please also check your spam folder."
        }
      },
      es: {
        translation: {
          activationFormTitle: "Programe la activación de su tarjeta SIM",
          activationFormSubtitle:
            "Por favor rellene el siguiente formulario para programar la activacion de su tarjeta SIM",
          nameInputLabel: "Nombre",
          nameInputEmptyError: "Por favor ingrese su nombre",

          emailInputLabel: "Correo electrónico",
          emailInputEmptyError: "Por favor ingrese su correo electrónico",
          emailInputInvalidError: "Ingrese un email válido",

          orderInputLabel: "Número de orden",
          orderInputEmpyError: "Ingresa tu número de orden",
          orderInputInvalidError: "El número de orden es inválido",

          iccidInputLabel: "Número de tarjeta SIM (ICCID) ",
          iccidCharacters: "19 caracteres",
          iccidInputEmptyError: "Ingrese su ICCID",
          iccidInputInvalidError: "ICCID inválido",

          pukInputLabel: "PUK",
          pukInputInvalidError: "PUK inválido",
          pukInputEmptyError: "Ingrese su PUK",

          imeiInputLabel: "IMEI (sin guiones)",
          imeiCharacters: "15 caracteres",
          imeiInputEmptyError: "Introduzca su IMEI sin guiones",
          imeiInputInvalidError: "IMEI inválido. Introdúzcalo sin guiones",
          imeiHelpText:
            "Para activar tu SIM, necesitamos el número IMEI de tu teléfono móvil. Puede encontrar esto en la configuración de su teléfono celular o después de marcar *#06# en la aplicación de teléfono de su teléfono celular.",
          activationDateInputLabel: "Fecha de activación",
          activationDateInputEmptyError: "Ingrese la fecha de activación",
          returnDateInputLabel: "Último día de viaje",
          returnDateInputEmptyError: "Ingrese la fecha de retorno",

          termsInputLabel: "Renuncia al derecho de desistimiento",
          terms:
            "Por la presente, renuncio al derecho de retiro de 14 días como se describe en los Términos y condiciones y deseo que beachsim.com comience el servicio ya en la fecha preferida indicada anteriormente.",
          send: "Enviar",

          successUrl: "https://www.beachsim.com/en/activation-of-lycamobile-us-sim-card-success/",

          support: "Contactar con soporte",
          supportUrl: "https://www.beachsim.com/en/contact/",

          imprint: "Aviso Legal",
          imprintUrl: "https://www.beachsim.com/en/imprint/",

          success: "Éxito",
          successMessage:
            "También recibirá un correo electrónico confirmando su programación para la activación. Si no recibe dicho correo electrónico (tampoco en la carpeta de spam), regrese aquí, programe nuevamente y asegúrese de que su dirección de correo electrónico esté escrita correctamente.",
          alradyScheduleOn: "Notamos que la activacion de tu tarjeta sim esta programada el: ",
          reScheduleTo:
            "Ya que las dos fechas son futuras, puedes hacer el cambio. Haz click en Continuar si estas seguro de cambiar la fecha programada por la siguiente: ",
          continue: "Continuar",
          back: "Regresar",
          rescheduleWithFailure:
            "Tu sim está actualmente en proceso de activación o ya ha sido activado. Nos contactaremos a la brevedad por correo electrónico",
          termsUnChecked: "Por favor acepte los términos y condiciones",
          fillAllData: "Por favor complete todos los campos requeridos",
          confirmationTitle: "Confirma tu información",
          confirmationMessage:
            "Estimado cliente, después de enviar este formulario, recibirá un correo electrónico de confirmación. Deberías recibirlo en los próximos minutos. Si no lo hace, primero verifique su carpeta de SPAM y asegúrese de que no esté allí. De lo contrario, programe nuevamente y asegúrese de escribir una dirección de correo electrónico válida."
        }
      },
      de: {
        translation: {
          activationFormTitle: "Aktivierung Ihrer Simkarte",
          activationFormSubtitle: "Bitte füllen Sie das Formular aus",
          nameInputLabel: "Ihr Name",
          nameInputEmptyError: "Bitte geben Sie Ihren Namen ein",

          emailInputLabel: "Ihre E-Mail-Adresse",
          emailInputEmptyError: "Bitte geben Sie ihre E-Mail-Adresse ein",
          emailInputInvalidError: "Bitte geben Sie eine gültige E-Mail-Adresse ein",

          orderInputLabel: "Ihre Bestellnummer",
          orderInputEmpyError: "Bitte Ihre Bestellnummer angeben",
          orderInputInvalidError: "Ungültige Bestellnummer",

          iccidInputLabel: "Simkarten-Nummer (ICCID)",
          iccidCharacters: "19 Zeichen",
          iccidInputEmptyError: "Bitte Simkarten-Nummer (ICCID) beginnend mit 89... angeben",
          iccidInputInvalidError: "Ungültige Simkarten-Nummer (ICCID)",

          pukInputLabel: "PUK",
          pukInputEmptyError: "Bitte geben Sie ihre PUK ein",
          pukInputInvalidError: "Ungültige PUK",

          imeiInputLabel: "IMEI (ohne Trennstriche)",
          imeiCharacters: "15 Zeichen",
          imeiInputEmptyError: "Bitte IMEI ohne Trennzeichen angeben",
          imeiInputInvalidError: "Ungültige IMEI. Bitte ohne Trennzeichen eingeben.",
          imeiHelpText:
            "Zur Aktivierung Ihrer SIM benötigen wir die IMEI Nummer Ihres Handys. Diese finden Sie in den Einstellungen Ihres Handys oder nach Eingabe von *#06# in der Telefon-App Ihres Handys.",

          activationDateInputLabel: "Aktivierungszeitpunkt",
          activationDateInputEmptyError: "Bitte Aktivierungszeitpunkt angeben",
          returnDateInputLabel: "Rückreisetag aus dem Reiseland",
          returnDateInputEmptyError: "Bitte Rückreisetag aus dem Reiseland angeben",

          activateAnotherCardButtonLabel: "Weitere Simkarte aktivieren",

          termsInputLabel: "Verzicht auf Widerrufsrecht",
          terms:
            "Hiermit verzichte ich ausdrücklich auf mein 14-tägiges Widerrufsrecht gemäß der Allgem. Geschäftsbedingungen und möchte meine bestellte Leistung (Reise-Simkarte mit Tarif) ab meinem angegebenen Wunschtermin nutzen.",
          send: "Absenden",

          successUrl: "https://www.beachsim.com/aktivierung-lycamobile-usa-abschluss/",

          support: "Support kontaktieren",
          supportUrl: "https://www.beachsim.com/kontakt/",

          imprint: "Impressum",
          imprintUrl: "https://www.beachsim.com/impressum/",

          success: "Erfolg",
          successMessage:
            "Sie erhalten in Kürze eine E-Mail, in der Ihr Zeitplan für die Aktivierung bestätigt wird. Bitte prüfen Sie auch Ihren Spam-Ordner. ",
          alradyScheduleOn: "Wir haben festgestellt, dass Ihre Karte bereits für die Aktivierung geplant ist am: ",
          reScheduleTo:
            "Da beide Daten in der Zukunft liegen, können Sie die Aktivierung ändern. Klicken Sie auf Weiter, wenn Sie sicher sind, dass Sie die Aktivierung Ihrer SIM-Karte auf dieses Datum verschieben möchten: ",
          continue: "Fortsetzen",
          back: "Zurück",
          rescheduleWithFailure:
            "Ihre Simkarte befindet sich gerade im Aktivierungsprozess oder wurde bereits aktiviert. Wir werden Sie in Kürze per E-Mail kontaktieren",
          termsUnChecked: "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen",
          fillAllData: "Bitte füllen Sie alle erforderlichen Felder aus",
          confirmationTitle: "Bestätigen Sie Ihre Angaben",
          confirmationMessage:
            "Sehr geehrter Kunde, nach dem Absenden dieses Formulars erhalten Sie innerhalb der nächsten Minuten eine Bestätigungs-E-Mail. Bitte prüfen Sie auch Ihren Spam-Ordner."
        }
      }
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });
