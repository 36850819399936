import get from "lodash/get";
import { DocumentData } from "@firebase/firestore-types";
import { functions } from "../utils/firebase";
import { User } from "../utils/types";
import { emailIsValid } from "../utils/validations";
import CustomError from "../utils/CustomError";

export function getUserDefaultState(data: DocumentData | undefined | null): User {
  return {
    uid: get(data, "uid", null),
    displayName: get(data, "displayName", ""),
    email: get(data, "email", ""),
    password: "",
    disabled: get(data, "disabled", false)
  };
}

export async function loadUser(uid: string): Promise<User> {
  const userDocumentFirebase = await functions?.httpsCallable("getUser")({
    uid
  });
  const data = getUserDefaultState(userDocumentFirebase?.data);
  return data;
}

export async function createUser(data: User): Promise<User> {
  if (!data.password && !data.displayName && !data.email) {
    throw new Error("In order to create an user, you need to provide information for Name, Email and Password");
  }
  if (!emailIsValid(data.email)) {
    throw new Error("Invalid email");
  }
  const disabled: boolean = get(data, "data.disabled", false);
  const ref = await functions?.httpsCallable("createUser")({
    displayName: data.displayName,
    email: data.email,
    disabled
  });

  return { uid: ref?.data.uid, ...ref?.data };
}

export async function updateUser(data: User): Promise<User> {
  if (!emailIsValid(data.email)) {
    throw new Error("Error, invalid email");
  }
  if (!data.password && !data.displayName && !data.email) {
    throw new Error("In order to update an user, you need to provide information for Name, Email and Password");
  }
  const ref = await functions?.httpsCallable("updateUser")({
    uid: data.uid,
    user: { ...data }
  });

  return { uid: ref?.data.uid, ...ref?.data };
}

export async function deleteUser(data: User): Promise<void> {
  await functions?.httpsCallable("deleteUser")({
    uid: data.uid
  });
}

export function validateUserData(data: User): void {
  const error = new CustomError("Invalid user data", "form-inputs-error");

  if (!data.displayName) {
    error.setError("displayName", "Id is required");
  }
  if (!data.email) {
    error.setError("email", "Email is required");
  }
  if (data.email && !emailIsValid(data.email)) {
    error.setError("email", "Email is invalid");
  }
  if (!data.password) {
    error.setError("password", "Password is required");
  }

  if (error.length) {
    throw error;
  }
}
