import React from "react";

import styles from "./styles.module.css";

interface Props {
  label: string;
}

const Header: React.FC<Props> = ({ label }) => {
  return (
    <div className={styles.header}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{label}</h2>
      </div>
    </div>
  );
};

export default Header;
