import get from "lodash/get";
import { Settings } from "../utils/types";
import { getDocRef } from "../utils/firebase";

export function getSettingsDefaultState(data?: unknown): Settings {
  return {
    ActivationsRunAt: get(data, "ActivationsRunAt", ""),
    runnerPower: get(data, "runnerPower", ""),
    timezone: get(data, "timezone", "")
  };
}

export async function load(): Promise<Settings> {
  const refNext = getDocRef("configs", "ActivationConfig");
  const snapshot = await refNext.get();

  return getSettingsDefaultState(snapshot.data());
}

export async function update(data: Settings): Promise<Settings> {
  const docData: Settings = getSettingsDefaultState(data);
  const ref = getDocRef("configs", "ActivationConfig");
  await ref.update(docData);

  return docData;
}
