import React, { ChangeEvent } from "react";

export interface Change {
  id: string;
  value: string | number | string[] | boolean | undefined | Date;
}
interface Props {
  id: string;
  value?: boolean;
  label?: string;
  text?: string;
  ref: React.Ref<HTMLInputElement>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  styles: { wrapper: string; input: string };
}

const InputCheckbox: React.FC<Props> = (props) => {
  const { id, value, onChange, styles } = props;
  return (
    <div className={styles.wrapper}>
      <input type="checkbox" id={id} className={styles.input} checked={value} onChange={onChange} />
    </div>
  );
};

export default React.forwardRef<React.FC, Props>((props, ref) => <InputCheckbox ref={ref} {...props} />);
