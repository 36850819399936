import React from "react";
import styles from "./styles.module.css";

interface Props {
  id?: string;
  value?: boolean;
  label?: string;
  text?: string;
  onChange?: (value: boolean) => void;
  showLabel?: boolean;
  showError?: boolean;
  error?: string;
}

const InputCheckbox: React.FC<Props> = (props) => {
  const { id, value, label, text, showLabel, showError, error, onChange } = props;
  return (
    <div className={styles.inputContainer}>
      {showLabel ? (
        <div className={styles.labelContainer}>
          <label className={styles.inputLabel} htmlFor={id}>
            {label}
          </label>
        </div>
      ) : null}

      <div className={styles.wrapper}>
        <input
          type="checkbox"
          id={id}
          className={styles.input}
          checked={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChange?.(e.target.checked)}
        />
        <label htmlFor={id} className={styles.text}>
          {text}
        </label>
      </div>

      {showError ? (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{error}</span>
        </div>
      ) : null}
    </div>
  );
};

InputCheckbox.defaultProps = {
  showLabel: true,
  showError: true
};

export default InputCheckbox;
