import React from "react";
import AuthHoc from "../../components/AuthHoc";
import XLSX from "xlsx";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { firestore, getAuth } from "../../utils/firebase";
import Layout from "../../components/Layout";
import styles from "./styles.module.css";
import { CardFirestoreDocument, CardBatchImport } from "../../utils/types";
import get from "lodash/get";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../utils/time";

interface State {
  inputData: CardBatchImport[];
  cards: CardFirestoreDocument[];
  parsed: boolean;
  process: string | null;
  status: string | null;
  message: string | null;
}

const toDate = (date: string): Date | null => (date ? moment(date, "MM/DD/YYYY hh:mm:ss A").toDate() : null);

class BatchSIMCreation extends React.Component<{}, State> {
  private inputFile: React.RefObject<HTMLInputElement>;
  constructor(props: {}) {
    super(props);
    this.state = {
      process: null,
      status: null,
      message: null,
      inputData: [],
      cards: [],
      parsed: false
    };

    this.inputFile = React.createRef();
  }

  handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    const file = files[0];

    this.handleFile(file);
  };

  onDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
  };

  handleFile = (file: File): void => {
    const reader = new FileReader();
    this.setState({ status: "sectionLoader" });
    reader.onload = (res): void => {
      if (res.target && res.target.result) {
        try {
          const workbook = XLSX.read(res.target.result, { type: "array" });
          const wsname = workbook.SheetNames[0];
          const ws = workbook.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          const inputData = data.map(
            (item: unknown): CardBatchImport => {
              const manualActivation = get(item, "manualActivation", false);
              return {
                ICCID: get(item, "ICCID", ""),
                wholesalerId: get(item, "wholeSaler", ""),
                SIMExpiryDate: toDate(get(item, "simExpiryDate", "")),
                pointOfSale: get(item, "pointOfSale", ""),
                plan: get(item, "planId", ""),
                FBADate: toDate(get(item, "FBADate", "")),
                orderNumberInternal: get(item, "internalOrderNumber", ""),
                orderNumberCustomer: get(item, "customerOrderNumber", ""),
                email: get(item, "customerEmail", ""),
                customerFullNameBilling: get(item, "customerBillingName", ""),
                customerFullNameActivation: get(item, "customerActivationName", ""),
                ASIN: get(item, "ASIN", ""),
                activationDate: toDate(get(item, "activationDate", "")),
                returnDate: toDate(get(item, "returnDate", "")),
                MSISDN: get(item, "MSISDN", ""),
                onlyManualActivation: manualActivation,
                language: get(item, "emailLanguage", "")
              };
            }
          );
          const cards: CardFirestoreDocument[] = inputData.map(
            (item): CardFirestoreDocument => {
              return {
                ...item,
                annotation: get(item, "annotation", ""),
                activatedAt: get(item, "activatedAt", null),
                activationAttempts: get(item, "activationAttempts", null),
                done: get(item, "done", false),
                updatedOrderNumberAt: get(item, "updatedOrderNumberAt", null),
                updatedAt: get(item, "updatedAt", null),
                scheduledAt: get(item, "scheduledAt", null),
                scheduledBy: get(item, "scheduledBy", ""),
                createdAt: new Date(),
                createdBy: getAuth().currentUser?.displayName || null,
                status: "stock",
                updatedBy: ""
              };
            }
          );
          this.setState({ inputData, cards, parsed: true });
        } catch {
          this.setState({ status: null });
        }
      }
    };

    reader.readAsArrayBuffer(file);
  };

  handleInputFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.target.files;
    const file = files ? files[0] : null;

    if (file) {
      this.handleFile(file);
    }
  };

  upload = async (): Promise<void> => {
    try {
      if (!firestore) {
        return;
      }
      this.setState({ process: "uploading", status: "uploading", message: "uploading" });

      const { cards } = this.state;
      const collection = firestore.collection("cards");
      const cardRefs = cards.map((item) => collection.doc(item.ICCID));

      await firestore.runTransaction(async (transaction) => {
        const cardSnapshots = await Promise.all(cardRefs.map((ref) => transaction.get(ref)));

        cardSnapshots.forEach((snap): void => {
          if (snap.exists) {
            throw new Error("duplicated-card");
          }
        });

        const sets = cardSnapshots.map((snap, index) => transaction.set(snap.ref, cards[index]));

        return Promise.all(sets);
      });

      this.setState({
        process: null,
        status: "success",
        message: "Successful upload"
      });
    } catch (error) {
      if (error.message === "duplicated-card") {
        return this.setState({
          process: null,
          status: "failure",
          message: "File contains duplicate card"
        });
      }

      this.setState({
        process: null,
        status: "failure",
        message: "Error"
      });
    }
  };

  clear = (): void => {
    this.setState({ inputData: [], cards: [], status: null, message: null });
  };

  openInputFile = (): void => {
    this.inputFile.current?.click();
  };

  render(): JSX.Element {
    const { inputData, message, status } = this.state;
    let lengthArr = 0;

    if (inputData.length) {
      lengthArr = Object.keys(inputData[0]).length;
    }

    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>Batch Import</h2>
            </div>
          </div>

          <div className={styles.content}>
            <input
              key={Date.now()}
              ref={this.inputFile}
              type="file"
              onChange={this.handleInputFile}
              style={{ display: "none" }}
            />

            <div className={styles.listTop}>
              {status === "uploading" ? <FontAwesomeIcon icon={faSpinner} spin /> : message}
              {inputData.length ? (
                <button onClick={this.upload}>
                  <span>Save {inputData.length} SIM cards</span>
                </button>
              ) : null}
              {inputData.length ? (
                <button onClick={this.clear}>
                  <span>Clear</span>
                </button>
              ) : null}
            </div>

            {inputData.length ? (
              <div className={styles.listDoc}>
                {inputData.length ? (
                  <div
                    className={styles.listHeader}
                    style={{
                      gridTemplateColumns: `${"1fr ".repeat(lengthArr + 1)}`
                    }}
                  >
                    <div />
                    {Object.keys(inputData[0]).map(
                      (el): JSX.Element => (
                        <div key={el}>{el}</div>
                      )
                    )}
                  </div>
                ) : null}
                {inputData.map((card, i) => (
                  <div
                    key={i}
                    className={styles.listRow}
                    style={{
                      gridTemplateColumns: `${"1fr ".repeat(lengthArr + 1)}`
                    }}
                  >
                    <div>{i + 1}</div>
                    <div>{card.ICCID}</div>
                    <div>{card.wholesalerId}</div>
                    <div>{formatDate(card.SIMExpiryDate)}</div>
                    <div>{card.pointOfSale}</div>
                    <div>{card.plan}</div>
                    <div>{formatDate(card.FBADate)}</div>
                    <div>{card.orderNumberInternal}</div>
                    <div>{card.orderNumberCustomer}</div>
                    <div>{card.email}</div>
                    <div>{card.customerFullNameBilling}</div>
                    <div>{card.customerFullNameActivation}</div>
                    <div>{card.ASIN}</div>
                    <div>{formatDate(card.activationDate)}</div>
                    <div>{formatDate(card.returnDate)}</div>
                    <div>{card.MSISDN}</div>
                    <div>{card.onlyManualActivation.toString()}</div>
                    <div>{card.language}</div>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
            <div className={styles.list}>
              {inputData.length ? null : (
                <div className={styles.dropAreaContainer}>
                  <div className={styles.dropArea} onDrop={this.handleDrop} onDragOver={this.onDragOver}>
                    <FontAwesomeIcon icon={faFileExcel} />
                    <h2>Please select a .xlsx file to upload</h2>
                    <div className={styles.spinner}>
                      {status === "sectionLoader" ? (
                        <h3>
                          <FontAwesomeIcon icon={faSpinner} spin />
                        </h3>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    <br />
                    <button onClick={this.openInputFile}>Select File</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AuthHoc(BatchSIMCreation);
