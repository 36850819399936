import XLSX from "xlsx";

export function exportJsonToFile(fileName: string, sheetName: string, data: unknown[]): void {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Sepito",
    Subject: "Export file from sepito portal",
    Author: "Sepito",
    CreatedDate: new Date()
  };

  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, fileName);
}

export function base64ToXLSX(data: string): void {
  const wb = XLSX.read(data, { type: "base64" });
  XLSX.writeFile(wb, "all-cards.xlsx");
}
