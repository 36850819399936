import React from "react";
import Header from "../SectionHeader";
import Layout from "../../components/Layout";

import styles from "./styles.module.css";

interface Props {
  label: string;
}

const SectionLayout: React.FC<Props> = ({ children, label }) => {
  return (
    <Layout>
      <div className={styles.container}>
        <Header label={label} />
        <div className={styles.content}>{children}</div>
      </div>
    </Layout>
  );
};

export default SectionLayout;
