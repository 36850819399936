import React from "react";
import { debounce, orderBy, uniqWith, isEqual, get } from "lodash";
import { getColRef, getDocRef, getFunctions } from "../../utils/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faSearch,
  faAngleDown,
  faAngleUp,
  faTrashAlt,
  faFileUpload,
  faPlus,
  faFileExport,
  faFileDownload,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SectionLayout from "../../components/SectionLayout";
import SectionLoader from "../../components/SectionLoader";
import NotFound from "../../components/NotFound";
import AuthHoc from "../../components/AuthHoc";
import { list, deleteCard, exportCardsFromBD, getCardListState } from "../../services/cards";
import { CardList } from "../../utils/types";
import { getDataInFinalScroll } from "../../utils/validations";
import ListItem from "./ListItem";
import styles from "./styles.module.css";
import InputText from "../../components/Input/Text";
import ConfirmDeleteModal from "../../components/ConfirmDelete";
import { exportJsonToFile } from "../../utils/exportJsonToFile";
import InputSelect from "../../components/Input/Select";
import InputDate from "../../components/Input/Date";
import { timestampSecondsToDate } from "../../utils/time";
import { Query } from "@firebase/firestore-types";
import moment from "moment";
import algoliasearch from "algoliasearch";

interface Filters {
  status: string;
  filterDateType: string;
  fromDate: Date | null;
  toDate: Date | null;
}

export interface State {
  status: "initialLoader" | "searchLoader" | "loading" | "loaded" | "failure";
  action: "" | "delete" | "filter" | "scroll" | "sort";
  search: string;
  sortColumnName: string;
  sortColumnDir: boolean | "asc" | "desc";
  itemsLimit: number;
  lastItem: string;
  dataLastLength: number;
  data: CardList[];
  selectedICCIDs: string[];
  modal: string;
  showFilters: boolean;
  sort: {
    field: string;
    direction: "desc" | "asc" | undefined;
  };
  filters: Filters;
  errorMessage: string;
}

class Cards extends React.Component<{}, State> {
  onSearchDebounce: (value: string) => void;
  constructor(props: {}) {
    super(props);
    this.state = {
      status: "initialLoader",
      action: "",
      search: "",
      sortColumnName: "ICCID",
      sortColumnDir: "asc",
      itemsLimit: 5000,
      lastItem: "",
      dataLastLength: 0,
      data: [],
      selectedICCIDs: [],
      modal: "",
      showFilters: false,
      sort: {
        field: "createdAt",
        direction: "desc"
      },
      filters: {
        status: "all",
        filterDateType: "createdAt",
        fromDate: null,
        toDate: null
      },
      errorMessage: ""
    };
    this.onSearchDebounce = debounce(this.onSearch, 500);
  }

  componentDidMount(): void {
    this.getCards();
  }

  componentDidUpdate(): void {
    if (["delete", "filter", "scroll", "sort"].indexOf(this.state.action) !== -1) {
      this.getCards();
    }
  }

  getCards = async (): Promise<void> => {
    const { data, itemsLimit, lastItem, sort, filters } = this.state;
    const nextData = await list({ itemsLimit, lastItem, sort, filters });

    console.log("getCards", nextData);

    this.setState({
      data: uniqWith([...data, ...nextData], isEqual),
      lastItem: nextData.slice(-1)[0]?.ICCID || "",
      dataLastLength: data.length,
      status: "loaded",
      action: "",
      errorMessage: ""
    });
  };

  onSearchTextChange = (value: string): void => {
    this.setState({
      search: value,
      status: "searchLoader"
    });
    this.onSearchDebounce(value);
  };

  onSearch = async (value: string): Promise<void> => {
    if (!!value) {
      try {
        const client = algoliasearch("YK0720HP19", "2307041a587bf963f01c81173bb3fb01");
        const index = client.initIndex("sepito_activation_tool");

        const { hits } = await index.search(value);

        // const dataSearch = await getFunctions().httpsCallable("esSearch")({
        //   text: value
        // });
        //
        const cards: any[] = [...hits];

        // dataSearch.data.data.forEach((card: CardList): void => {
        //   cards.push({
        //     ...card,
        //     activationDate: get(card, "activationDate._seconds", null)
        //       ? timestampSecondsToDate(get(card, "activationDate._seconds", null))
        //       : null
        //   });
        // });

        this.setState({
          data: cards,
          status: "loaded",
          errorMessage: ""
        });
      } catch (error) {
        this.setState({
          data: [],
          status: "failure",
          errorMessage: error.message
        });
      }
    } else {
      this.setState({
        data: [],
        status: "loaded",
        errorMessage: "",
        lastItem: ""
      });
      console.log("getc");
      this.getCards();
    }
  };

  handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    if (getDataInFinalScroll(e, this.state.data.length, this.state.dataLastLength)) {
      this.setState({ status: "loading", action: "scroll" });
    }
  };

  selectedICCID = (ICCID: string, operation: string): void => {
    const selectedICCIDs = [...this.state.selectedICCIDs];

    if (operation === "add") {
      selectedICCIDs.push(ICCID);
    } else if (operation === "remove") {
      const index = this.state.selectedICCIDs.indexOf(ICCID);
      if (index > -1) {
        selectedICCIDs.splice(index, 1);
      }
    }

    this.setState({ selectedICCIDs });
  };

  deleteSelectedCards = async (): Promise<void> => {
    this.setState({ status: "initialLoader" });
    const selected = this.state.selectedICCIDs.map((item) => deleteCard(item));
    await Promise.all(selected);
    this.setState({ action: "delete", data: [], lastItem: "", selectedICCIDs: [] });
  };

  sortColumn = (sortColumnName: string, sortColumnDir: boolean | "asc" | "desc"): void => {
    this.setState({
      sortColumnDir: sortColumnDir === "asc" ? "desc" : "asc",
      sortColumnName,
      data: orderBy(this.state.data, [sortColumnName], [sortColumnDir])
    });
  };

  onChangeFilters = (change: Partial<Filters>): void => {
    console.log("onChangeFilters", change);

    this.setState((state) => ({
      status: "initialLoader",
      action: "filter",
      lastItem: "",
      dataLastLength: 0,
      data: [],
      filters: {
        ...state.filters,
        ...change
      }
    }));
  };

  createFile = (): void => {
    exportJsonToFile("cards.xlsx", "Cards", this.state.data);
  };

  exportDB = async (): Promise<void> => {
    this.setState({ status: "loading" });
    await exportCardsFromBD();
    this.setState({ status: "loaded" });
  };

  render(): JSX.Element {
    const { data, status, sortColumnName, sortColumnDir, modal, filters, errorMessage } = this.state;

    const faAngleDirection = (sortColumnDir: boolean | "asc" | "desc"): IconDefinition =>
      sortColumnDir === "asc" ? faAngleUp : faAngleDown;

    return (
      <SectionLayout label="Cards">
        <ConfirmDeleteModal
          open={modal === "delete"}
          onClose={(): void => this.setState({ modal: "" })}
          onClick={this.deleteSelectedCards}
        />

        <div className={styles.header}>
          <div className={styles.search_input}>
            <InputText
              id="search"
              value={this.state.search}
              onChange={this.onSearchTextChange}
              placeholder="Iccid, order, customer"
              showLabel={false}
            />
            <div className={styles.search_icon}>
              {status === "searchLoader" ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faSearch} />
              )}
            </div>
          </div>
          <div className={styles.filter_box}>
            <InputSelect
              id="status"
              label="Status"
              value={filters.status}
              onChange={(value): void => this.onChangeFilters({ status: value })}
              options={[
                {
                  key: "all",
                  label: "All",
                  value: "all"
                },
                {
                  key: "stock",
                  label: "Stock",
                  value: "stock"
                },
                {
                  key: "updated",
                  label: "Updated",
                  value: "updated"
                },
                {
                  key: "scheduled",
                  label: "Scheduled",
                  value: "scheduled"
                },
                {
                  key: "activated",
                  label: "Activated (API)",
                  value: "activated-api"
                },
                {
                  key: "activated",
                  label: "Activated (Manually)",
                  value: "activated-manually"
                },
                {
                  key: "failed",
                  label: "Failed",
                  value: "failed"
                }
              ]}
            />
            <InputSelect
              id="filterDateType"
              label="Filter date by"
              value={filters.filterDateType}
              onChange={(value): void => this.onChangeFilters({ filterDateType: value })}
              options={[
                {
                  key: "createdAt",
                  label: "Created",
                  value: "createdAt"
                },
                {
                  key: "updatedAt",
                  label: "Updated",
                  value: "updatedAt"
                },
                {
                  key: "activationDate",
                  label: "Scheduled",
                  value: "activationDate"
                },
                {
                  key: "activatedAt",
                  label: "Activated",
                  value: "activatedAt"
                }
              ]}
            />
            <InputDate
              id="fromDate"
              label="From date"
              value={filters.fromDate}
              onChange={(value): void => this.onChangeFilters({ fromDate: value })}
              popperPlacement="top-end"
              startDate={null}
              endDate={null}
            />
            <InputDate
              id="toDate"
              label="To date"
              value={filters.fromDate != null && filters.toDate === null ? filters.fromDate : filters.toDate}
              onChange={(value): void => this.onChangeFilters({ toDate: value })}
              startDate={filters.fromDate}
              endDate={filters.toDate}
              minDate={filters.fromDate}
              popperPlacement="top-end"
            />
          </div>
          <div className={styles.icons_panel}>
            <Link to="/admin/cards/new">
              <div className={styles.tooltip_buttons}>
                <FontAwesomeIcon icon={faPlus} className={styles.icon} />
                <span className={styles.tooltiptext}>New Card</span>
              </div>
            </Link>
            <Link to="/admin/batch">
              <div className={styles.tooltip_buttons}>
                <FontAwesomeIcon icon={faFileUpload} className={styles.file_upload} />
                <span className={styles.tooltiptext}>Import batch</span>
              </div>
            </Link>
            <div className={styles.tooltip_buttons} onClick={(): Promise<void> => this.exportDB()}>
              {status === "loading" ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faFileDownload} className={styles.composesIcon} />
              )}
              <span className={styles.tooltiptext}>Export all</span>
            </div>
            <div className={styles.tooltip_export} onClick={(): void => this.createFile()}>
              <FontAwesomeIcon icon={faFileExport} className={styles.file_download} />
              <span className={styles.tooltiptext}>Export list</span>
            </div>
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.listHeader}>
            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("ICCID", sortColumnDir)}>
              ICCID{"  "}
              {sortColumnName === "ICCID" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("pointOfSale", sortColumnDir)}>
              Point of sale{"  "}
              {sortColumnName === "pointOfSale" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div
              className={styles.listHeaderSort}
              onClick={(): void => this.sortColumn("orderNumberCustomer", sortColumnDir)}
            >
              Customer order number {"  "}
              {sortColumnName === "orderNumberCustomer" ? (
                <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} />
              ) : (
                ""
              )}
            </div>

            <div
              className={styles.listHeaderSort}
              onClick={(): void => this.sortColumn("orderNumberInternal", sortColumnDir)}
            >
              Internal order number {"  "}
              {sortColumnName === "orderNumberInternal" ? (
                <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} />
              ) : (
                ""
              )}
            </div>

            <div
              className={styles.listHeaderSort}
              onClick={(): void => this.sortColumn("activationDate", sortColumnDir)}
            >
              Activation date{"  "}
              {sortColumnName === "activationDate" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("returnDate", sortColumnDir)}>
              Return date{"  "}
              {sortColumnName === "returnDate" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("MSISDN", sortColumnDir)}>
              MSISDN{"  "}
              {sortColumnName === "MSISDN" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div
              className={styles.listHeaderSort}
              onClick={(): void => this.sortColumn("customerBillingName", sortColumnDir)}
            >
              Customer billing name{"  "}
              {sortColumnName === "customerBillingName" ? (
                <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} />
              ) : (
                ""
              )}
            </div>

            <div
              className={styles.listHeaderSort}
              onClick={(): void => this.sortColumn("customerActivationName", sortColumnDir)}
            >
              Customer activation name{"  "}
              {sortColumnName === "customerActivationName" ? (
                <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} />
              ) : (
                ""
              )}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("plan.name", sortColumnDir)}>
              Plan{"  "}
              {sortColumnName === "plan.name" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("wholesalerId", sortColumnDir)}>
              Wholesaler{"  "}
              {sortColumnName === "wholesalerId" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("FBADate", sortColumnDir)}>
              FBA date{"  "}
              {sortColumnName === "FBADate" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("status", sortColumnDir)}>
              Status{"  "}
              {sortColumnName === "status" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("annotation", sortColumnDir)}>
              Annotation{"  "}
              {sortColumnName === "annotation" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("done", sortColumnDir)}>
              Done{"  "}
              {sortColumnName === "done" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("done", sortColumnDir)}>
              Manually done{"  "}
              {sortColumnName === "done" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("updatedAt", sortColumnDir)}>
              Updated at{"  "}
              {sortColumnName === "updatedAt" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>

            <div>
              <button
                className={
                  this.state.selectedICCIDs.length > 0
                    ? styles.listHeader_delete_enable
                    : styles.listHeader_delete_disable
                }
                onClick={(): void => {
                  if (this.state.selectedICCIDs.length > 0) this.setState({ modal: "delete" });
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
          <div className={styles.overflow_list} onScroll={(e): void => this.handleScroll(e)}>
            {["deleted", "initialLoader"].indexOf(status) !== -1 ? (
              <SectionLoader />
            ) : data.length === 0 ? (
              <NotFound message={errorMessage} />
            ) : (
              <>
                {data.map(
                  (card): JSX.Element => (
                    <ListItem data={card} key={card.ICCID} selectedICCID={this.selectedICCID} />
                  )
                )}
                <div className={styles.footer}>
                  {["loading"].indexOf(status) !== -1 ? <FontAwesomeIcon icon={faSpinner} spin /> : ""}
                </div>
              </>
            )}
          </div>
        </div>
      </SectionLayout>
    );
  }
}

export default AuthHoc(Cards);
