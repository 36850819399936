import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { RouteComponentProps } from "react-router-dom";
import SectionLayout from "../../components/SectionLayout";
import SectionLoader from "../../components/SectionLoader";
import AuthHoc from "../../components/AuthHoc";
import {
  scheduleActivation,
  loadCard,
  getCardDefaultState,
  updateCard,
  createCard,
  deleteCard,
  activateSIM,
  sendActivationEmail
} from "../../services/cards";
import { Card } from "../../utils/types";
import InputText from "../../components/Input/Text";
import styles from "./styles.module.css";
import InputDate from "../../components/Input/Date";
import { formatDate } from "../../utils/time";
import PlansModal from "../../components/PlansModal";
import { SIM_ACTIVATION_SUCCESS } from "../../utils/constan";
import ConfirmDeleteModal from "../../components/ConfirmDelete";
import ActivationAttempts from "../ActivationAttempts";
import Checkbox from "../../components/Input/Checkbox";
import InputSelect from "../../components/Input/Select";
import CustomError from "../../utils/CustomError";

interface Params {
  id: string;
}

export interface State {
  status: "initialLoader" | "loading" | "loaded" | "failure";
  message: string;
  modal: string;
  data: Card;
  error: CustomError | null;
}

class Cards extends React.Component<RouteComponentProps<Params>, State> {
  constructor(props: RouteComponentProps<Params>) {
    super(props);
    this.state = {
      status: "initialLoader",
      message: "",
      modal: "",
      data: getCardDefaultState(null),
      error: null
    };
  }

  componentDidMount(): void {
    this.load();
  }

  load = async (): Promise<void> => {
    try {
      if (this.props.match.params.id) {
        const data = await loadCard(this.props.match.params.id);

        this.setState({ status: "loaded", data });
      } else {
        this.setState({ status: "loaded", data: getCardDefaultState(null) });
      }
    } catch (error) {
      this.setState({ status: "failure", error });
    }
  };

  create = async (): Promise<void> => {
    this.setState({ status: "loading", message: "", error: null });
    try {
      const data = await createCard(this.state.data);
      this.setState({ status: "loaded", data, message: "Card Created!" });
      this.props.history.push(`/admin/cards/${data.ICCID}`);
    } catch (error) {
      this.setState({ status: "failure", error });
    }
  };

  update = async (): Promise<void> => {
    try {
      this.setState({ status: "loading", message: "", error: null });
      const dataDoc = await updateCard(this.state.data);
      this.setState({ status: "loaded", message: "Card Updated!", data: dataDoc });
    } catch (error) {
      this.setState({ status: "failure", error });
    }
  };

  delete = async (): Promise<void> => {
    try {
      this.setState({ status: "loading", message: "", error: null });
      await deleteCard(this.state.data.id);
      this.setState({ status: "loaded", message: "Card Deleted!" });
      this.props.history.push(`/admin/cards`);
    } catch (error) {
      this.setState({ status: "failure", error });
    }
  };

  onActivation = async (): Promise<void> => {
    try {
      this.setState({ status: "loading", message: "", error: null });
      const MSISDN = await activateSIM(this.state.data);
      this.setState({ message: `${SIM_ACTIVATION_SUCCESS} MSISDN: ${MSISDN}` });
      this.load();
    } catch (error) {
      this.setState({ status: "failure", error });
    }
  };

  onSchedule = async (): Promise<void> => {
    try {
      this.setState({ status: "loading", message: "", error: null });
      await scheduleActivation(this.state.data);
      this.setState({ message: "Card has been scheduled" });
      this.load();
    } catch (error) {
      this.setState({ status: "failure", error: error });
    }
  };

  onSendActivationEmail = async (): Promise<void> => {
    this.setState({ status: "loading", message: "", error: null });
    try {
      await sendActivationEmail(this.state.data);
      this.setState({ status: "loaded", message: "Email successfully sent" });
    } catch (error) {
      this.setState({
        status: "failure",
        message: error.message
      });
    }
  };

  render(): JSX.Element {
    const { status, message, modal, data, error } = this.state;

    console.log(data);

    return (
      <SectionLayout label={status === "initialLoader" ? "Card" : data.id ? `Card` : "New Card"}>
        <ConfirmDeleteModal
          open={modal === "delete"}
          onClose={(): void => this.setState({ modal: "" })}
          onClick={this.delete}
        />
        <PlansModal
          open={modal === "plans"}
          onClose={(): void => this.setState({ modal: "" })}
          onClick={(value): void => this.setState({ data: { ...data, plan: value } })}
        />

        {status === "initialLoader" ? (
          <SectionLoader />
        ) : (
          <>
            <div className={styles.container}>
              <div className={styles.row}>
                <InputText
                  id="ICCID"
                  label="ICCID:"
                  value={data.ICCID}
                  onChange={(value): void => this.setState({ data: { ...data, ICCID: value } })}
                  disabled={!data.createdAt ? false : true}
                  error={error?.getError("ICCID")}
                />
                <InputText
                  id="wholesalerId"
                  label={`Wholesaler: ${data.wholesalerId === "" ? "*" : ""}`}
                  value={data.wholesalerId}
                  onChange={(value): void => this.setState({ data: { ...data, wholesalerId: value } })}
                  error={error?.getError("wholesalerId")}
                />
                <InputDate
                  id="SIMExpiryDate"
                  label={`SIM expiration date: ${data.SIMExpiryDate === null ? "*" : ""}`}
                  value={data.SIMExpiryDate}
                  onChange={(value): void => this.setState({ data: { ...data, SIMExpiryDate: value } })}
                  minDate={new Date()}
                  popperPlacement="top-start"
                  startDate={null}
                  endDate={null}
                  error={error?.getError("SIMExpiryDate")}
                />
              </div>
              <div className={styles.row}>
                <InputText
                  label="Point of sale:"
                  id="pointOfSale"
                  value={data.pointOfSale}
                  onChange={(value): void => this.setState({ data: { ...data, pointOfSale: value } })}
                  error={error?.getError("pointOfSale")}
                />
                <InputText
                  readOnly
                  label="Plan:"
                  value={get(data, "plan.description", "")}
                  onClick={(): void => this.setState({ modal: "plans" })}
                  error={error?.getError("plan")}
                />
                <InputDate
                  id="FBADate"
                  label="FBA date:"
                  value={data.FBADate}
                  onChange={(value): void => this.setState({ data: { ...data, FBADate: value } })}
                  minDate={new Date()}
                  popperPlacement="top-start"
                  startDate={null}
                  endDate={null}
                  error={error?.getError("FBADate")}
                />
              </div>
              <div className={styles.row}>
                <InputText
                  label="Internal order number:"
                  id="orderNumberInternal"
                  value={data.orderNumberInternal}
                  onChange={(value): void => this.setState({ data: { ...data, orderNumberInternal: value } })}
                  error={error?.getError("orderNumberInternal")}
                />
                <InputText
                  label="Customer order number:"
                  id="orderNumberCustomer"
                  value={data.orderNumberCustomer}
                  onChange={(value): void => this.setState({ data: { ...data, orderNumberCustomer: value } })}
                  error={error?.getError("orderNumberCustomer")}
                />
                <InputText
                  label="Customer email:"
                  id="email"
                  value={data.email}
                  onChange={(value): void => this.setState({ data: { ...data, email: value } })}
                  error={error?.getError("email")}
                />
              </div>
              <div className={styles.row}>
                <InputText
                  label="Customer billing name:"
                  id="customerFullNameBilling"
                  value={data.customerFullNameBilling}
                  onChange={(value): void => this.setState({ data: { ...data, customerFullNameBilling: value } })}
                  error={error?.getError("customerFullNameBilling")}
                />
                <InputText
                  label="Customer activation name:"
                  id="customerFullNameActivation"
                  value={data.customerFullNameActivation}
                  onChange={(value): void => this.setState({ data: { ...data, customerFullNameActivation: value } })}
                  error={error?.getError("customerFullNameActivation")}
                />
                <InputText
                  label="ASIN:"
                  id="ASIN"
                  value={data.ASIN}
                  onChange={(value): void => this.setState({ data: { ...data, ASIN: value } })}
                  error={error?.getError("ASIN")}
                />
              </div>
              <div className={styles.row}>
                <InputDate
                  id="activationDate"
                  label="Activation date:"
                  value={data.activationDate}
                  minDate={new Date()}
                  popperPlacement="top-start"
                  startDate={null}
                  endDate={null}
                  onChange={(value): void => {
                    this.setState({ data: { ...data, activationDate: value } });
                    if (moment(this.state.data.activationDate).isSameOrBefore(moment(value))) {
                      this.setState({ data: { ...data, activationDate: moment(value).add(1, "day").toDate() } });
                    }
                  }}
                  error={error?.getError("activationDate")}
                />
                <InputDate
                  id="returnDate"
                  label="Return date:"
                  value={data.returnDate}
                  minDate={data.activationDate ? moment(data.activationDate).add(1, "day").toDate() : null}
                  popperPlacement="top-start"
                  startDate={null}
                  endDate={null}
                  onChange={(value): void => this.setState({ data: { ...data, returnDate: value } })}
                  error={error?.getError("returnDate")}
                />
                <InputText
                  label="MSISDN:"
                  id="MSISDN"
                  value={data.MSISDN}
                  onChange={(value): void => this.setState({ data: { ...data, MSISDN: value } })}
                  error={error?.getError("MSISDN")}
                />
                <InputText
                  label="IMEI:"
                  id="IMEI"
                  value={data.imei}
                  onChange={(value): void => this.setState({ data: { ...data, imei: value } })}
                  error={error?.getError("IMEI")}
                />
                <InputText
                  label="PUK:"
                  id="PUK"
                  value={data.puk}
                  onChange={(value): void => this.setState({ data: { ...data, puk: value } })}
                  error={error?.getError("PUK")}
                />
                <InputText
                  label="Annotation:"
                  id="annotation"
                  value={data.annotation}
                  onChange={(value): void => this.setState({ data: { ...data, annotation: value } })}
                  error={error?.getError("annotation")}
                />
                <Checkbox
                  label="Done"
                  id="done"
                  value={data.done}
                  onChange={(value): void => this.setState({ data: { ...data, done: value } })}
                />
              </div>
              <div className={styles.row}>
                <Checkbox
                  label="Manual activation only:"
                  id="onlyManualActivation"
                  value={data.onlyManualActivation}
                  onChange={(value): void => this.setState({ data: { ...data, onlyManualActivation: value } })}
                />
                <InputSelect
                  id="language"
                  label="Selected email language:"
                  value={data.language ? data.language : "de"}
                  onChange={(value): void => this.setState({ data: { ...data, language: value } })}
                  options={[
                    {
                      key: "de",
                      label: "German (Default)",
                      value: "de"
                    },
                    {
                      key: "en",
                      label: "English",
                      value: "en"
                    },
                    {
                      key: "es",
                      label: "Spanish",
                      value: "es"
                    }
                  ]}
                />
              </div>

              <div className={styles.row_action_info}>
                <div className={styles.message_box}>
                  {status === "loading" ? (
                    <div className={styles.message}>
                      <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                  ) : (
                    <div className={status === "failure" ? styles.messageRed : styles.message}>
                      {error ? error.message : message}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.row_buttons}>
                <div className={!data.id ? styles.row_back_button : ""}>
                  <Link to={"/admin/cards"} className={styles.button}>
                    Back
                  </Link>
                </div>
                {!data.id ? (
                  <div>
                    <button className={styles.button} onClick={this.create}>
                      Create
                    </button>
                  </div>
                ) : (
                  <>
                    {!data.activatedAt ? (
                      <>
                        <div>
                          <button className={styles.button} onClick={this.onSendActivationEmail}>
                            Activation email
                          </button>
                        </div>
                        <div>
                          <button className={styles.button} onClick={this.onSchedule}>
                            Schedule
                          </button>
                        </div>
                        <div>
                          <button className={styles.button} onClick={this.onActivation}>
                            Activate
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div>
                      <button className={styles.button} onClick={this.update}>
                        Update
                      </button>
                    </div>
                    <div>
                      <button className={styles.delete_button} onClick={(): void => this.setState({ modal: "delete" })}>
                        Delete
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        <br />

        {data.id ? (
          <div className={styles.container}>
            <div className={styles.row_info}>
              <div>Created by: {data.createdBy}</div>
              <div>Created at: {formatDate(data.createdAt)}</div>
              <div>Card status: {data.status}</div>
            </div>
            <div className={styles.row_info}>
              <div>Scheduled by: {data.scheduledBy ? `${data.scheduledBy}` : "----"}</div>
              <div>Scheduled at: {data.scheduledAt ? `${formatDate(data.scheduledAt)}` : "----"}</div>
              <div>Activation date: {data.activationDate ? `${formatDate(data.activationDate)}` : "----"}</div>
            </div>
            <div className={styles.row_info}>
              <div>Updated by: {data.updatedBy ? `${data.updatedBy}` : "----"}</div>
              <div>Updated at: {data.updatedAt ? `${formatDate(data.updatedAt)}` : "----"}</div>
              <div>Activated at: {data.activatedAt ? `${formatDate(data.activatedAt)}` : "----"}</div>
            </div>
          </div>
        ) : (
          ""
        )}
        <br />
        {data.activationAttempts ? <ActivationAttempts data={data.activationAttempts} /> : <></>}
      </SectionLayout>
    );
  }
}

export default AuthHoc(Cards);
