import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

interface Option {
  id: string;
  icon: IconDefinition;
  label: string;
  path: string;
}
interface Props {
  title?: string;
  logo?: string;
  logout?: () => never;
  options: Option[];
  user?: {
    displayName: string;
    photoURL: string;
  };
}
interface State {
  menuOpen: boolean;
}
export default class SideNavBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { menuOpen: false };
    window.addEventListener("click", this.closeMenu);
  }
  componentWillUnmount(): void {
    window.removeEventListener("click", this.closeMenu);
  }
  closeMenu = (): void => {
    this.setState({ menuOpen: false });
  };
  toggleMenu = (e: React.MouseEvent): void => {
    e.stopPropagation();
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  };
  render(): JSX.Element {
    const { options, title, logout, logo, user } = this.props;
    const { menuOpen } = this.state;
    return (
      <div className={styles.leftBar}>
        {logo || title ? (
          <Link className={styles.logoContainer} to="/admin">
            {logo ? <img src={logo} className={styles.logo} alt="logo" /> : null}
            <span>{title}</span>
          </Link>
        ) : null}
        <div className={styles.options}>
          {options.map(
            (option): JSX.Element => (
              <Link className={styles.leftBarButton} to={option.path} key={option.path}>
                <div>
                  <FontAwesomeIcon icon={option.icon} />
                </div>
                <div>{option.label}</div>
              </Link>
            )
          )}
        </div>
        {user ? (
          <div className={styles.usermenu}>
            <button onClick={this.toggleMenu} className={styles.userMenuToggleButton}>
              <img className={styles.userImg} src={user.photoURL} alt="" />
              <div className={styles.username}>{user.displayName}</div>
            </button>
            {menuOpen ? (
              <div className={styles.userMenuButtons}>
                <button onClick={logout}>Logout</button>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
