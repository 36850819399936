import React from "react";
import moment from "moment";
import { RouteComponentProps } from "react-router-dom";
import { faToggleOn, faToggleOff, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import AuthHoc from "../../components/AuthHoc";
import SectionLayout from "../../components/SectionLayout";
import InputSelect from "../../components/Input/Select";
import { getSettingsDefaultState, load, update } from "../../services/settings";
import SectionLoader from "../../components/SectionLoader";
import { Settings } from "../../utils/types";
import styles from "./styles.module.css";

interface Params {
  id: string;
}

export interface State {
  status: "initialLoader" | "loading" | "loaded" | "success" | "failure";
  message: string | null;
  modal: string;
  data: Settings;
}

class SettingsSection extends React.Component<RouteComponentProps<Params>, State> {
  constructor(props: RouteComponentProps<Params>) {
    super(props);
    this.state = {
      status: "initialLoader",
      modal: "",
      message: "",
      data: getSettingsDefaultState()
    };
  }

  componentDidMount(): void {
    this.load();
  }

  load = async (): Promise<void> => {
    try {
      const data = await load();
      this.setState({ status: "loaded", data });
    } catch (error) {
      this.setState({
        status: "failure",
        message: "An unexpected error ocurred"
      });
    }
  };

  onChange = (change: Partial<Settings>): void => {
    this.setState((state) => ({ data: { ...state.data, ...change } }));
  };

  save = async (): Promise<void> => {
    this.setState({ status: "loading" });
    try {
      const updatedData = await update(this.state.data);
      this.setState({ status: "loaded", message: "Settings saved", data: updatedData });
    } catch {
      this.setState({ message: "failure" });
    }
  };

  render(): JSX.Element {
    const { message, data, status } = this.state;
    const UTCOptions = [];
    const UTCExist = [];
    const hourSaved = parseInt(data.ActivationsRunAt.split(":")[0]);
    const hoursPlus = hourSaved <= 12 ? 12 : 12 - (hourSaved - 12);
    const hoursLess = hourSaved >= 12 ? 12 : hourSaved;
    const selectTime = new Date(
      2010,
      1,
      1,
      Number(data.ActivationsRunAt.split(":")[0]),
      Number(data.ActivationsRunAt.split(":")[1]),
      0
    );

    for (let i = hoursLess; i > 0; i--) {
      UTCOptions.push({
        key: `-${i}`,
        label: `UTC -${i}`,
        value: `-${i}`
      });
      UTCExist.push(`-${i}`);
    }

    for (let i = 0; i <= hoursPlus; i++) {
      UTCOptions.push({
        key: `+${i}`,
        label: `UTC +${i}`,
        value: `+${i}`
      });
      UTCExist.push(`+${i}`);
    }

    return (
      <SectionLayout label="">
        <div className={styles.container}>
          {status === "initialLoader" ? (
            <SectionLoader />
          ) : (
            <>
              <div className={styles.containerSection}>
                <div className={styles.row_title}>Task runner</div>
                <div className={styles.rowTwo}>
                  <div>
                    {data.runnerPower === "on" ? (
                      <FontAwesomeIcon
                        icon={faToggleOn}
                        className={styles.toogleOn}
                        onClick={(): void => this.onChange({ runnerPower: "off" })}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faToggleOff}
                        className={styles.toogleOff}
                        onClick={(): void => this.onChange({ runnerPower: "on" })}
                      />
                    )}
                  </div>
                  <div>
                    <div className={styles.inputContainer}>
                      <DatePicker
                        selected={selectTime}
                        onChange={(value): void => {
                          this.onChange({
                            ActivationsRunAt: moment(value instanceof Date ? value : "").format("HH:mm")
                          });
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm aa"
                        className={styles.input}
                      />
                    </div>
                  </div>
                  <div>
                    <InputSelect
                      id="timezone"
                      label=""
                      value={UTCExist.includes(data.timezone) ? data.timezone : "+0"}
                      onChange={(value): void => this.onChange({ runnerPower: value })}
                      options={UTCOptions}
                    />
                  </div>
                </div>
                <div className={styles.row_button}>
                  {status === "loading" ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <div className={status === "failure" ? styles.messageRed : styles.message}>{message}</div>
                  )}
                  <div className={styles.buttons}>
                    <button className={styles.button} onClick={this.save}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </SectionLayout>
    );
  }
}

export default AuthHoc(SettingsSection);
