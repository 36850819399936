import firebase from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyBndTYly51Eekpllkd4HHDEE8iBocC_F7Y",
  authDomain: "sepito-4af5d.firebaseapp.com",
  databaseURL: "https://sepito-4af5d.firebaseio.com",
  projectId: "sepito-4af5d",
  storageBucket: "sepito-4af5d.appspot.com",
  messagingSenderId: "303924116134",
  appId: "1:303924116134:web:cdb6477e6669cbd49b1267",
  measurementId: "G-08XJQTZKNG"
});

if (location.hostname === "localhost") {
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
}

firebase.analytics();

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

export default firebase;

export function getFirestore(): firebase.firestore.Firestore {
  if (firebase.firestore) {
    return firebase.firestore();
  }

  throw new Error("firestore-not-initialized");
}
export function getAuth(): firebase.auth.Auth {
  if (firebase.auth) {
    return firebase.auth();
  }

  throw new Error("auth-not-initialized");
}
export function getCurrentUser(): firebase.User | null {
  return getAuth().currentUser;
}
export function requireUser(): firebase.User {
  const user = getCurrentUser();
  if (user) {
    return user;
  }
  throw new Error("require-signin");
}
export function getFunctions(): firebase.functions.Functions {
  if (functions) {
    return functions;
  }

  throw new Error("firestore-not-initialized");
}

export function getColRef(collection: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
  return getFirestore().collection(collection);
}
export function getDocRef(
  collection: string,
  document?: string
): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
  if (collection && document) {
    return getColRef(collection).doc(document);
  }
  return getColRef(collection).doc();
}
