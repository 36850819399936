import { DocumentSnapshot, DocumentData, Query } from "@firebase/firestore-types";
import get from "lodash/get";
import { getDocRef, getColRef, requireUser, getFirestore } from "../utils/firebase";
import { Plan } from "../utils/types";
import { dateFromTimestamp } from "../utils/time";
import CustomError from "../utils/CustomError";

export interface PlansListFilters {
  itemsLimit?: number;
  lastItem?: string;
}

export function planDefaultState(data: DocumentData | undefined | null): Plan {
  const createdAt = get(data, "createdAt", null);
  const updatedAt = get(data, "updatedAt", null);
  return {
    id: get(data, "id", ""),
    bundleCode: get(data, "bundleCode", ""),
    months: get(data, "months", ""),
    internationalTopUpCode: get(data, "internationalTopUpCode", ""),
    monthlyPrice: get(data, "monthlyPrice", ""),
    planAmount: get(data, "planAmount", ""),
    regulatoryFee: get(data, "regulatoryFee", ""),
    zipCode: get(data, "zipCode", ""),
    description: get(data, "description", ""),
    provider: get(data, "provider", ""),
    type: get(data, "type", "preloadedPlan"),
    createdAt: createdAt ? dateFromTimestamp(createdAt) : null,
    updatedAt: updatedAt ? dateFromTimestamp(updatedAt) : null,
    createdBy: get(data, "createdBy", null)
  };
}

function planFromSnapshot(snapshot: DocumentSnapshot): Plan {
  if (snapshot.exists) {
    const data = snapshot.data() || {};
    return planDefaultState({ id: snapshot.id, ...data });
  }
  return planDefaultState(null);
}

export async function listPlans(opts?: PlansListFilters): Promise<Plan[]> {
  try {
    let snapshotNext;
    if (opts?.lastItem) {
      const refNext = getDocRef("plans", opts.lastItem);
      snapshotNext = await refNext.get();
    }

    const ref: Query = getColRef("plans")
      .orderBy("createdAt", "desc")
      .startAfter(snapshotNext ? snapshotNext : "")
      .limit(opts?.itemsLimit ? opts?.itemsLimit : 4);
    const snapshot = await ref.get();

    if (snapshot.empty) {
      return [];
    }

    return snapshot.docs.map(planFromSnapshot);
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function showPlan(id: string): Promise<Plan> {
  const ref = getDocRef("plans", id);
  const snapshot = await ref.get();
  const data = planFromSnapshot(snapshot);
  return data;
}

export async function createPlan(input: Plan): Promise<Plan> {
  const data = {
    bundleCode: get(input, "bundleCode", ""),
    months: get(input, "months", ""),
    internationalTopUpCode: get(input, "internationalTopUpCode", ""),
    monthlyPrice: get(input, "monthlyPrice", ""),
    planAmount: get(input, "planAmount", ""),
    regulatoryFee: get(input, "regulatoryFee", ""),
    zipCode: get(input, "zipCode", ""),
    description: get(input, "description", ""),
    provider: get(input, "provider", ""),
    type: get(input, "type", ""),
    createdBy: requireUser().displayName,
    createdAt: new Date(),
    updatedAt: null
  };

  const ref = getColRef("plans").doc(input.id);
  await ref.set(data);

  return { id: ref.id, ...data };
}

export async function updatePlan(input: Plan): Promise<Plan> {
  const ref = getDocRef("plans", input.id);
  const data = {
    bundleCode: get(input, "bundleCode", ""),
    months: get(input, "months", ""),
    internationalTopUpCode: get(input, "internationalTopUpCode", ""),
    monthlyPrice: get(input, "monthlyPrice", ""),
    planAmount: get(input, "planAmount", ""),
    regulatoryFee: get(input, "regulatoryFee", ""),
    zipCode: get(input, "zipCode", ""),
    description: get(input, "description", ""),
    provider: get(input, "provider", ""),
    type: get(input, "type", ""),
    createdBy: get(input, "createdBy", ""),
    createdAt: get(input, "createdAt", null),
    updatedAt: new Date()
  };

  await ref.update(data);

  return { id: input.id, ...data };
}

export async function deletePlan(id: string): Promise<void> {
  const cards = await getFirestore().collection("cards").where("plan", "==", id).get();
  if (!cards.empty) {
    throw new Error("Unable to delete, some SIM cards use this plan.");
  }
  await getDocRef("plans", id).delete();
  return;
}

export function validatePlanData(data: Plan): void {
  const error = new CustomError("Invalid plan data", "form-inputs-error");

  if (!data.id) {
    error.setError("id", "Id is required");
  }
  if (!data.bundleCode) {
    error.setError("bundleCode", "Bundle code is required");
  }
  if (!data.months) {
    error.setError("months", "Months is required");
  }
  if (!data.provider) {
    error.setError("provider", "Provider is required");
  }
  if (!data.description) {
    error.setError("description", "Description is required");
  }
  if (!data.monthlyPrice) {
    error.setError("monthlyPrice", "Monthly price is required");
  }
  if (!data.planAmount) {
    error.setError("planAmount", "Plan amount is required");
  }
  if (!data.regulatoryFee) {
    error.setError("regulatoryFee", "Regulatory fee is required");
  }
  if (!data.zipCode) {
    error.setError("regulatoryFee", "Regulatory fee is required");
  }
  if (data.type === "payg") {
    if (!data.internationalTopUpCode) {
      error.setError("internationalTopUpCode", "International top up code fee is required");
    }
  }

  if (error.length) {
    throw error;
  }
}
