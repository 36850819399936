import React from "react";
import { Redirect } from "react-router-dom";
import { User } from "@firebase/auth-types";
import { getAuth } from "../../utils/firebase";

interface State {
  loaded: boolean;
  user: User | null;
}

const PrivateRouteWrapper = (WrappedComponent: React.ElementType) =>
  class PrivateRoute extends React.Component<{}, State> {
    unsubscribe: () => void;
    constructor(props: {}) {
      super(props);
      this.state = { loaded: false, user: null };
      this.unsubscribe = getAuth().onAuthStateChanged((user) => {
        this.setState({ loaded: true, user });
      });
    }
    componentWillUnmount() {
      this.unsubscribe();
    }
    render() {
      const { loaded, user } = this.state;

      if (!loaded) {
        return null;
      }

      if (user) {
        return <WrappedComponent {...this.props} user={user} />;
      }

      return <Redirect to="/admin/login" />;
    }
  };

export default PrivateRouteWrapper;
