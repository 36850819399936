import React from "react";
import styles from "./styles.module.css";
import { Plan } from "../../utils/types";

interface Props {
  data: Plan;
  onClick: (data: Plan) => void;
}

const Item: React.FC<Props> = ({ onClick, data }): JSX.Element => {
  return (
    <button onClick={(): void => onClick(data)} className={styles.item}>
      <div>{data.description}</div>
    </button>
  );
};

export default Item;
