import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { User } from "../../utils/types";
import { validateInputLink } from "../../utils/validations";

interface Props {
  data: User;
}

const UserListItem: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const { listRowSelectText } = styles;
  return (
    <Link to={`/admin/users/${data.uid}`} draggable={false} className={styles.listRow} key={data.uid}>
      <div onClick={(e): void => validateInputLink(e)}>
        <span className={listRowSelectText}>{data.displayName}</span>
      </div>
      <div onClick={(e): void => validateInputLink(e)}>
        <span className={listRowSelectText}>{data.email}</span>
      </div>
      <div onClick={(e): void => validateInputLink(e)}>
        <span className={listRowSelectText}>{data.disabled ? "NO" : "YES"}</span>
      </div>
    </Link>
  );
};

export default UserListItem;
