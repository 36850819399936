import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.css";

interface Props {
  id: string;
  value?: Date | null;
  label?: string;
  placeholder?: string;
  password?: boolean;
  onChange?: (value: Date, id?: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onHelp?: () => void;
  minDate?: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  showTime?: boolean;
  containerStyle?: string;
  popperPlacement: string | any;
  showLabel?: boolean;
  showError?: boolean;
  error?: string;
}

const InputDate: React.FC<Props> = (props) => {
  const {
    id,
    value,
    label,
    onChange,
    onHelp,
    onBlur,
    minDate,
    startDate,
    endDate,
    popperPlacement,
    showTime,
    containerStyle,
    showLabel,
    showError,
    error
  } = props;

  const change = (value: Date): void => onChange?.(value, id);

  const isSameDay = moment().isSame(value || new Date(), "day");
  const isSameDayMinDate = minDate ? moment(minDate).isSame(value || new Date(), "day") : false;
  const minTime = isSameDayMinDate
    ? minDate || new Date()
    : isSameDay
    ? value || new Date()
    : moment(value || new Date())
        .startOf("day")
        .toDate();
  const maxTime = moment(value || new Date())
    .endOf("day")
    .toDate();

  return (
    <div className={`${styles.inputContainer} ${containerStyle || ""}`}>
      {showLabel ? (
        <div className={styles.labelContainer}>
          <label className={styles.inputLabel} htmlFor={id}>
            {label}
          </label>
          {onHelp ? (
            <button onClick={onHelp} className={styles.helpButton} type="button">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </button>
          ) : null}
        </div>
      ) : null}
      <DatePicker
        minTime={minTime}
        maxTime={maxTime}
        showTimeSelect={showTime ? showTime : false}
        timeFormat="HH:mm"
        dateFormat={"dd.MM.yyyy"}
        locale={"de"}
        minDate={minDate}
        startDate={startDate}
        endDate={endDate}
        className={`${styles.input} ${error ? styles.inputError : ""}`}
        selected={value}
        timeIntervals={60}
        onChange={change}
        onBlur={onBlur}
        popperPlacement={popperPlacement}
        selectsEnd
        selectsStart
      />
      {showError ? (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{error}</span>
        </div>
      ) : null}
    </div>
  );
};

InputDate.defaultProps = {
  showLabel: true,
  showError: true
};

export default InputDate;
