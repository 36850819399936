import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { CardList } from "../../utils/types";
import { formatDate } from "../../utils/time";
import InputCheckbox from "../../components/Input/BaseCheckbox";
import { validateInputLink, validateCheckboxLink } from "../../utils/validations";

export interface Change {
  id: string;
  value: string | number | string[] | boolean | undefined | Date;
}

interface Props {
  data: CardList;
  selectedICCID: (ICCID: string, operation: string) => void;
  onChange?: (change: Change) => void;
}

const CardListItem: React.FC<Props> = (props: Props) => {
  const { data, selectedICCID } = props;
  const { listRowSelectText, listRowCard } = styles;

  return (
    <>
      <Link to={`/admin/cards/${data.ICCID}`} draggable="false" className={styles.listRow} key={data.ICCID}>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.ICCID}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.pointOfSale}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.orderNumberCustomer}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.orderNumberInternal}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{formatDate(data.activationDate, { withTime: true })}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{formatDate(data.returnDate)}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.MSISDN}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.customerFullNameBilling}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.customerFullNameActivation}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.plan}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.wholesalerId}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{formatDate(data.FBADate)}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.status}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.annotation}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.done ? "✅" : "❌"}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{data.onlyManualActivation && data.MSISDN && data.done && "✅"}</span>
        </div>
        <div onClick={(e): void => validateInputLink(e)} className={listRowCard}>
          <span className={listRowSelectText}>{formatDate(data.updatedAt)}</span>
        </div>

        <div onClick={(e): void => validateCheckboxLink(e)} className={listRowCard}>
          <InputCheckbox
            styles={{ wrapper: styles.selectorCheckbox, input: "" }}
            id={data.ICCID}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              if (e.target.checked) {
                selectedICCID(data.ICCID, "add");
              } else {
                selectedICCID(data.ICCID, "remove");
              }
            }}
          />
        </div>
      </Link>
    </>
  );
};

export default CardListItem;
