import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { Plan } from "../../utils/types";
import { validateInputLink } from "../../utils/validations";

interface Props {
  data: Plan;
}

const PlanListItem: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const { listRowSelectText } = styles;

  if (!data) {
    return null;
  }

  return (
    <Link to={`/admin/plans/${data.id}`} draggable="false" className={styles.listRow} key={data.bundleCode}>
      <div onClick={(e): void => validateInputLink(e)}>
        <span className={listRowSelectText}>{data.provider}</span>
      </div>
      <div onClick={(e): void => validateInputLink(e)}>
        <span className={listRowSelectText}>{data.bundleCode}</span>
      </div>
      <div onClick={(e): void => validateInputLink(e)}>
        <span className={listRowSelectText}>{data.internationalTopUpCode}</span>
      </div>
      <div onClick={(e): void => validateInputLink(e)}>
        <span className={listRowSelectText}>{data.months}</span>
      </div>
      <div onClick={(e): void => validateInputLink(e)}>
        <span className={listRowSelectText}>{data.description}</span>
      </div>
      <div onClick={(e): void => validateInputLink(e)}>
        <span className={listRowSelectText}>{data.planAmount}</span>
      </div>
    </Link>
  );
};

export default PlanListItem;
