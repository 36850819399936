import React from "react";
import Modal from "../Modal";
import styles from "./styles.module.css";
import { listPlans } from "../../services/plans";
import { Plan } from "../../utils/types";
import { getDataInFinalScroll } from "../../utils/validations";
import Item from "./Item";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick: (data: Plan) => void;
}

export interface State {
  status: "initialLoader" | "loading" | "loaded" | "failure";
  itemsLimit: number;
  lastItem: string;
  dataLastLength: number;
  data: Plan[];
}

export default class PlansModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: "initialLoader",
      itemsLimit: 50,
      lastItem: "",
      dataLastLength: 0,
      data: []
    };
  }

  componentDidMount(): void {
    this.getPlans();
  }

  getPlans = async (): Promise<void> => {
    const { itemsLimit, data } = this.state;
    this.setState({ status: "loading" });

    const nextData = await listPlans({
      itemsLimit,
      lastItem: data.slice(-1)[0]?.id
    });

    this.setState({ data: [...data, ...nextData], dataLastLength: data.length, status: "loaded" });
  };

  handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    if (getDataInFinalScroll(e, this.state.data.length, this.state.dataLastLength)) {
      this.getPlans();
    }
  };

  render(): JSX.Element {
    const { onClose, onClick, open } = this.props;
    const { status, data } = this.state;

    if (status !== "initialLoader") {
      return (
        <Modal onClose={onClose} open={open}>
          <div className={styles.container} onScroll={(e): void => this.handleScroll(e)}>
            <div className={styles.title}>Plans</div>
            <div className={styles.list}>
              {data.map((data) => (
                <Item data={data} onClick={onClick} key={data.id} />
              ))}
              <div className={styles.footer_loader}>
                {status === "loading" ? <FontAwesomeIcon icon={faSpinner} spin /> : ""}
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    return (
      <Modal onClose={onClose} open={open}>
        <div className={styles.loading}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      </Modal>
    );
  }
}
