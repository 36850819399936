import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { withTranslation, WithTranslation } from "react-i18next";
import { functions } from "../../utils/firebase";
import Text from "../../components/Input/Text";
import Checkbox from "../../components/Input/Checkbox";
import InputDate from "../../components/Input/Date";
import Button from "../../components/Button";
import Logo from "../../assets/logo.png";
import English from "../../assets/flags/english.png";
import German from "../../assets/flags/german.png";
import Spanish from "../../assets/flags/spanish.png";
import styles from "./styles.module.css";
import { formatDate } from "../../utils/time";
import { checkICCID, checkImei, checkOrderNumber, checkPuk, emailIsValid } from "../../utils/validations";
import CustomError from "../../utils/CustomError";

type Props = WithTranslation;
interface State {
  error: CustomError | null;
  status: string | "success" | "confirmation" | "saving" | "failure";
  message: string;
  username: string;
  email: string;
  order: string;
  type: string;
  iccid: string;
  activationDate: Date | null;
  returnDate: Date | null;
  imei: string;
  puk: string;
  terms: boolean;
}
const initialState: State = {
  error: null,
  status: "",
  message: "",
  imei: "",
  puk: "",
  username: "",
  email: "",
  order: "",
  type: "usa",
  iccid: "",
  activationDate: null,
  returnDate: null,
  terms: false
};

class Activation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
    document.title = "BeachSIM Activation";
  }

  save = async (): Promise<void> => {
    const { t } = this.props;
    try {
      const { username, email, order, iccid, imei, puk, activationDate, returnDate } = this.state;

      this.setState({ status: "saving", message: "" });

      fetch("https://hooks.zapier.com/hooks/catch/9559236/buds7bm/", {
        method: "POST",
        body: JSON.stringify({
          username,
          email,
          order,
          iccid,
          activationDate: (activationDate as Date).toDateString(),
          returnDate: (returnDate as Date).toISOString(),
          language: this.props.i18n.language ? this.props.i18n.language : "de",
          scheduledBy: "Schedule form",
          updatedBy: "Schedule form"
        })
      });

      await functions?.httpsCallable("scheduleActivation")({
        username,
        email,
        order,
        iccid,
        imei,
        puk,
        activationDate: moment(activationDate).add(6, "hours").toISOString(),
        returnDate: (returnDate as Date).toISOString(),
        language: this.props.i18n.language ? this.props.i18n.language : "de",
        scheduledBy: "Schedule form",
        updatedBy: "Schedule form"
      });

      this.setState({ status: "success" });
    } catch (error) {
      if (error.message === "failed") {
        return this.setState({ status: "failure", message: t("rescheduleWithFailure") });
      }

      if (error.code === "not-found") {
        return this.setState({ status: "failure", message: error.message });
      }

      if (error.code === "already-exists") {
        return this.setState({ status: "failure" });
      }

      return this.setState({ status: "failure", message: "An unexpected error ocurred" });
    }
  };

  onReset = (): void => {
    this.setState(initialState);
  };

  onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    this.schedule();
  };

  schedule = (): void => {
    const { t } = this.props;
    const { username, email, order, iccid, imei, puk, activationDate, returnDate, terms } = this.state;

    const error = new CustomError(t("fillAllData"), "invalid-data");

    if (!username) {
      error.setError("username", t("nameInputEmptyError"));
    }
    if (!email) {
      error.setError("email", t("emailInputEmptyError"));
    }
    if (email && !emailIsValid(email)) {
      error.setError("email", t("emailInputInvalidError"));
    }
    if (!order) {
      error.setError("order", t("orderInputEmpyError"));
    }
    if (!iccid) {
      error.setError("iccid", t("iccidInputEmptyError"));
    }
    if (iccid && !checkICCID(iccid)) {
      error.setError("iccid", t("iccidInputInvalidError"));
    }
    if (!imei && this.showIMEIInput(iccid)) {
      error.setError("imei", t("imeiInputEmptyError"));
    }

    if (imei && !checkImei(imei)) {
      error.setError("imei", t("imeiInputInvalidError"));
    }

    if (!puk && this.showPukInput(iccid)) {
      error.setError("puk", t("pukInputEmptyError"));
    }

    if (puk && !checkPuk(puk)) {
      error.setError("puk", t("pukInputInvalidError"));
    }
    if (!activationDate) {
      error.setError("activationDate", t("activationDateInputEmptyError"));
    }
    if (!returnDate) {
      error.setError("returnDate", t("returnDateInputEmptyError"));
    }
    if (!terms) {
      error.setError("terms", t("termsUnChecked"));
    }

    if (error.length) {
      this.setState({ error });
      return;
    } else {
      this.setState({ message: "" });
    }

    this.setState({ status: "confirmation" });
  };

  showIMEIInput = (iccid: string): boolean => {
    if (iccid.startsWith("890")) {
      return true;
    }
    return false;
  };

  showPukInput = (iccid: string): boolean => {
    if (iccid.startsWith("89196")) {
      return true;
    }
    return false;
  };

  render(): JSX.Element {
    const {
      error,
      status,
      message,
      username,
      email,
      order,
      iccid,
      puk,
      imei,
      activationDate,
      returnDate,
      terms
    } = this.state;
    const { t } = this.props;

    const form = (
      <div className={styles.form}>
        <div className={styles.inputRow}>
          <Text
            id="username"
            label={t("nameInputLabel")}
            value={username}
            onChange={(value): void => this.setState({ username: value })}
            error={error?.getError("username")}
          />
          <Text
            label={t("emailInputLabel")}
            id="email"
            value={email}
            onChange={(value): void => this.setState({ email: value })}
            error={error?.getError("email")}
          />
        </div>

        <div className={styles.inputRow}>
          <Text
            id="order"
            label={t("orderInputLabel")}
            value={order}
            onChange={(value): void => this.setState({ order: value })}
            error={error?.getError("order")}
          />
          <Text
            id="iccid"
            label={t("iccidInputLabel")}
            value={iccid}
            onChange={(value): void => this.setState({ iccid: value })}
            error={error?.getError("iccid")}
          />

          {this.showIMEIInput(iccid) && (
            <div className={styles.imeiRow}>
              <Text
                id="imei"
                label={t("imeiInputLabel")}
                value={imei}
                onChange={(value): void => this.setState({ imei: value })}
                error={error?.getError("imei")}
              />
              <div style={{ marginTop: "-0.5rem" }}>{t("imeiHelpText")}</div>
            </div>
          )}

          {this.showPukInput(iccid) && (
            <div className={styles.imeiRow}>
              <Text
                id="puk"
                label={t("pukInputLabel")}
                value={puk}
                onChange={(value): void => this.setState({ puk: value })}
                error={error?.getError("puk")}
              />
            </div>
          )}
        </div>

        <div className={styles.inputRow}>
          <InputDate
            id="activationDate"
            label={t("activationDateInputLabel")}
            value={activationDate}
            onChange={(value): void => {
              this.setState({ activationDate: value });
              if (moment(this.state.returnDate).isSameOrBefore(moment(value))) {
                this.setState({
                  returnDate: moment(value).add(1, "day").toDate()
                });
              }
            }}
            minDate={new Date()}
            popperPlacement="top-start"
            startDate={null}
            endDate={null}
            error={error?.getError("activationDate")}
          />
          <InputDate
            id="returnDate"
            label={t("returnDateInputLabel")}
            value={returnDate}
            onChange={(value): void => this.setState({ returnDate: value })}
            minDate={moment(activationDate || new Date())
              .add(1, "day")
              .toDate()}
            popperPlacement="top-start"
            startDate={null}
            endDate={null}
            error={error?.getError("returnDate")}
          />
        </div>
        <br />
        <Checkbox
          id="terms"
          label={t("termsInputLabel")}
          text={t("terms")}
          value={terms}
          onChange={(value): void => this.setState({ terms: value })}
          error={error?.getError("terms")}
        />
        <div className={styles.message}>{message}</div>
        <div className={styles.buttons}>
          <Button label={t("continue")} style={styles.sendButton} onClick={this.schedule} />
        </div>
      </div>
    );

    if (status === "success") {
      window.location.href = t("successUrl");
    }

    const confirmation = (
      <form className={styles.form} onSubmit={this.onSubmit}>
        <h3 className={styles.confirmTitle}>{t("confirmationTitle")}</h3>
        <br />
        <p className={styles.confirmText}>{t("confirmationMessage")}</p>
        <br />
        <div className={styles.confirmRow}>
          <div className={styles.inlineBlock}>{t("nameInputLabel")} </div>
          <div className={styles.inlineBlockRight}>{username}</div>
        </div>
        <div className={styles.confirmRow}>
          <div className={styles.inlineBlock}>{t("emailInputLabel")}</div>
          <div className={styles.inlineBlockRight}>{email}</div>
        </div>
        <div className={styles.confirmRow}>
          <div className={styles.inlineBlock}>{t("orderInputLabel")}</div>
          <div className={styles.inlineBlockRight}>{order}</div>
        </div>
        <div className={styles.confirmRow}>
          <div className={styles.inlineBlock}>{t("iccidInputLabel")}</div>
          <div className={styles.inlineBlockRight}>{iccid}</div>
        </div>
        <div className={styles.confirmRow}>
          <div className={styles.inlineBlock}>{t("activationDateInputLabel")}</div>
          <div className={styles.inlineBlockRight}>{formatDate(activationDate)}</div>
        </div>
        <div className={styles.confirmRow}>
          <div className={styles.inlineBlock}>{t("returnDateInputLabel")}</div>
          <div className={styles.inlineBlockRight}>{formatDate(returnDate)}</div>
        </div>
        <div className={styles.message}>{message}</div>
        <div className={styles.buttons}>
          <Button
            label={t("back")}
            style={styles.backButton}
            onClick={(): void => {
              this.setState({ status: "" });
            }}
          />
          <Button
            onClick={this.save}
            type="button"
            label={t("send")}
            style={styles.sendButton}
            loading={status === "saving" || status === "success"}
          />
        </div>
      </form>
    );

    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div>
              <div>
                <img
                  src={English}
                  alt="English language"
                  className={styles.languages}
                  onClick={(): void => window.location.assign("?lng=en")}
                />
                <img
                  src={Spanish}
                  alt="Spanish language"
                  className={styles.languages}
                  onClick={(): void => window.location.assign("?lng=es")}
                />
                <img
                  src={German}
                  alt="German language"
                  className={styles.languages}
                  onClick={(): void => window.location.assign("?lng=de")}
                />
              </div>
              <h2 className={styles.title}>{t("activationFormTitle")}</h2>
              <p className={styles.subtitle}>{t("activationFormSubtitle")}</p>
            </div>
            <img className={styles.logo} src={Logo} alt="BeachSIM.com" />
          </div>
          {status === "confirmation" || status === "saving" || status === "success" ? confirmation : form}
        </div>
        <div className={styles.footer}>
          <a href={t("supportUrl")} className={styles.supportLink} target="_blank" rel="noopener noreferrer">
            {t("support")}
          </a>
          <a href={t("imprintUrl")} className={styles.supportLink} target="_blank" rel="noopener noreferrer">
            {t("imprint")}
          </a>
          <div className={styles.copyright}>©{moment().format("YYYY")} beachSIM.com</div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Activation);
