import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import AuthHoc from "../../components/AuthHoc";
import styles from "./styles.module.css";
import SectionLayout from "../../components/SectionLayout";
import SectionLoader from "../../components/SectionLoader";
import InputText from "../../components/Input/Text";
import { User } from "../../utils/types";
import { getUserDefaultState, validateUserData } from "../../services/users";
import Checkbox from "../../components/Input/Checkbox";
import { loadUser, createUser, updateUser, deleteUser } from "../../services/users";
import ConfirmDeleteModal from "../../components/ConfirmDelete";
import CustomError from "../../utils/CustomError";

interface Params {
  id: string;
}

export interface State {
  data: User;
  status: "initialLoader" | "loading" | "loaded" | "success" | "failure";
  message: string | null;
  modal: string;
  error: CustomError | null;
}

class Users extends React.Component<RouteComponentProps<Params>, State> {
  constructor(props: RouteComponentProps<Params>) {
    super(props);
    this.state = {
      data: getUserDefaultState(null),
      modal: "",
      status: "initialLoader",
      message: null,
      error: null
    };
  }

  componentDidMount(): void {
    this.load();
  }

  load = async (): Promise<void> => {
    this.setState({
      status: this.state.status === "initialLoader" ? "initialLoader" : "loading"
    });
    try {
      if (this.props.match.params.id) {
        const data = await loadUser(this.props.match.params.id);
        this.setState({ status: "loaded", data });
      } else {
        this.setState({ status: "loaded", data: getUserDefaultState(null) });
      }
    } catch (error) {
      this.setState({
        status: "failure",
        message: "An unexpected error ocurred"
      });
    }
  };

  create = async (): Promise<void> => {
    this.setState({ status: "loading", message: "" });
    try {
      validateUserData(this.state.data);
      const data = await createUser(this.state.data);
      this.setState({ status: "loaded", data, message: "User Created!" }, () =>
        this.props.history.push(`/admin/users/${data.uid}`)
      );
    } catch (error) {
      this.setState({
        status: "failure",
        error
      });
    }
  };

  update = async (): Promise<void> => {
    this.setState({ status: "loading", message: "" });
    try {
      validateUserData(this.state.data);
      await updateUser(this.state.data);
      this.setState({ status: "loaded", message: "User Updated!" });
    } catch (error) {
      this.setState({
        status: "failure",
        error
      });
    }
  };

  delete = async (): Promise<void> => {
    try {
      const { data } = this.state;
      this.setState({ status: "loading", message: "" });
      await deleteUser(data);
      this.setState({ status: "loaded", message: "User Deleted!" }, () => this.props.history.push(`/admin/users`));
    } catch (error) {
      this.setState({
        status: "failure",
        message: "An unexpected error ocurred"
      });
    }
  };

  onChange = (change: Partial<User>): void => {
    this.setState((state) => ({ data: { ...state.data, ...change } }));
  };

  render(): JSX.Element {
    const { data, message, status, modal, error } = this.state;
    return (
      <SectionLayout label={status === "initialLoader" ? "User:" : data.uid ? `User: ${data.displayName}` : "New User"}>
        <ConfirmDeleteModal
          open={modal === "delete"}
          onClose={(): void => this.setState({ modal: "" })}
          onClick={this.delete}
        />
        <div className={styles.container}>
          {status === "initialLoader" ? (
            <SectionLoader />
          ) : (
            <>
              <div className={styles.row}>
                <InputText
                  id="displayName"
                  label={`Name: *`}
                  value={data.displayName}
                  onChange={(value): void => this.onChange({ displayName: value })}
                  error={error?.getError("displayName")}
                />
                <InputText
                  id="email"
                  label={`Email: *`}
                  value={data.email}
                  onChange={(value): void => this.onChange({ email: value })}
                  error={error?.getError("email")}
                />
                <InputText
                  id="password"
                  label={`${data.uid ? "New Password: *" : "Password"}`}
                  value={data.password}
                  password
                  onChange={(value): void => this.onChange({ password: value })}
                  error={error?.getError("password")}
                />
                <Checkbox
                  label="Disabled"
                  id="disabled"
                  value={data.disabled}
                  onChange={(value): void => this.onChange({ disabled: value })}
                />
              </div>
              <br />
              <div className={styles.buttons}>
                {["loading"].indexOf(status) !== -1 ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <div className={styles.message}>{message}</div>
                )}
                <Link to={"/admin/users"} className={styles.button}>
                  Back
                </Link>
                {!data.uid ? (
                  <button className={styles.button} onClick={this.create}>
                    Create
                  </button>
                ) : (
                  <>
                    <button className={styles.button} onClick={this.update}>
                      Update
                    </button>
                    <button
                      className={styles.delete_button}
                      onClick={(): void => {
                        this.setState({ modal: "delete" });
                      }}
                    >
                      Delete
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </SectionLayout>
    );
  }
}

export default AuthHoc(Users);
