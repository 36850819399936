import get from "lodash/get";
import moment from "moment";
import { DocumentSnapshot, DocumentData, Query, FieldPath } from "@firebase/firestore-types";
import { Card, CardFirestoreDocument, ActivationAttempt, CardList } from "../utils/types";
import { dateFromTimestamp } from "../utils/time";
import { getColRef, getDocRef, getFunctions, requireUser, getFirestore, getCurrentUser } from "../utils/firebase";
import { showPlan } from "./plans";
import { checkICCID, emailIsValid } from "../utils/validations";
import { base64ToXLSX } from "../utils/exportJsonToFile";
import CustomError from "../utils/CustomError";
import { SIM_ACTIVATION_FAIL, SIM_SCHEDULE_FAIL } from "../utils/constan";

export interface CardListFilters {
  itemsLimit: number;
  lastItem: string;
  sort: {
    field: string | FieldPath;
    direction: "desc" | "asc" | undefined;
  };
  filters: {
    status: string;
    filterDateType: string;
    fromDate: Date | null;
    toDate: Date | null;
  };
}

export async function isICCIDExist(iccid: string): Promise<boolean> {
  const doc = await getFirestore().collection("cards").doc(iccid).get();
  return doc.exists;
}

export function getCardkey(ICCID: string, order: string): string {
  if (order !== null && order !== "") return order.substr(order.length - 3) + ICCID.substr(3);
  return "";
}

function getActivationAttemptDefaultState(data: DocumentData | undefined | null): ActivationAttempt {
  const attemptAt = get(data, "attemptAt", null);
  return {
    attemptAt: attemptAt ? dateFromTimestamp(attemptAt) : null,
    status: get(data, "status", null),
    description: get(data, "description", null)
  };
}

function getActivationAttempts(data: ActivationAttempt[] | null): ActivationAttempt[] {
  const attempts: ActivationAttempt[] = [];
  if (data) {
    data.forEach((attempt) => {
      attempts.push(getActivationAttemptDefaultState(attempt));
    });
  }
  return attempts;
}

export function getCardDefaultState(data: DocumentData | undefined | null): Card {
  const SIMExpiryDate = get(data, "SIMExpiryDate", null);
  const createdAt = get(data, "createdAt", null);
  const updatedAt = get(data, "updatedAt", null);
  const scheduledAt = get(data, "scheduledAt", null);
  const activationDate = get(data, "activationDate", null);
  const returnDate = get(data, "returnDate", null);
  const updatedOrderNumberAt = get(data, "updatedOrderNumberAt", null);
  const activatedAt = get(data, "activatedAt", null);
  const FBADate = get(data, "FBADate", null);
  const activationAttempts = getActivationAttempts(get(data, "activationAttempts", null));
  return {
    id: get(data, "id", null),
    activatedAt: activatedAt ? dateFromTimestamp(activatedAt) : null,
    activationAttempts: activationAttempts,
    activationDate: activationDate ? dateFromTimestamp(activationDate) : null,
    annotation: get(data, "annotation", ""),
    ASIN: get(data, "ASIN", ""),
    createdAt: createdAt ? dateFromTimestamp(createdAt) : null,
    createdBy: get(data, "createdBy", ""),
    customerFullNameActivation: get(data, "customerFullNameActivation", ""),
    customerFullNameBilling: get(data, "customerFullNameBilling", ""),
    done: get(data, "done", false),
    email: get(data, "email", ""),
    ICCID: get(data, "ICCID", ""),
    language: get(data, "language", ""),
    MSISDN: get(data, "MSISDN", null),
    imei: get(data, "imei", null),
    puk: get(data, "puk", null),
    onlyManualActivation: get(data, "onlyManualActivation", false),
    orderNumberCustomer: get(data, "orderNumberCustomer", ""),
    orderNumberInternal: get(data, "orderNumberInternal", ""),
    updatedOrderNumberAt: updatedOrderNumberAt ? dateFromTimestamp(updatedOrderNumberAt) : null,
    FBADate: FBADate ? dateFromTimestamp(FBADate) : null,
    plan: get(data, "plan", ""),
    pointOfSale: get(data, "pointOfSale", ""),
    returnDate: returnDate ? dateFromTimestamp(returnDate) : null,
    SIMExpiryDate: SIMExpiryDate ? dateFromTimestamp(SIMExpiryDate) : null,
    scheduledAt: scheduledAt ? dateFromTimestamp(scheduledAt) : null,
    scheduledBy: get(data, "scheduledBy", ""),
    status: get(data, "status", ""),
    updatedAt: updatedAt ? dateFromTimestamp(updatedAt) : null,
    updatedBy: get(data, "updatedBy", ""),
    wholesalerId: get(data, "wholesalerId", "")
  };
}

export function getCardFirestoreDocument(data: Card | null): CardFirestoreDocument {
  return {
    activatedAt: get(data, "activatedAt", null),
    activationAttempts: get(data, "activationAttempts", null),
    activationDate: get(data, "activationDate", null),
    ASIN: get(data, "ASIN", ""),
    annotation: get(data, "annotation", ""),
    createdAt: get(data, "createdAt", null),
    createdBy: get(data, "createdBy", null),
    customerFullNameActivation: get(data, "customerFullNameActivation", ""),
    customerFullNameBilling: get(data, "customerFullNameBilling", ""),
    done: get(data, "done", false),
    email: get(data, "email", ""),
    ICCID: get(data, "ICCID", ""),
    IMEI: get(data, "IMEI", ""),
    puk: get(data, "puk", ""),
    language: get(data, "language", ""),
    MSISDN: get(data, "MSISDN", ""),
    onlyManualActivation: get(data, "onlyManualActivation", false),
    orderNumberCustomer: get(data, "orderNumberCustomer", ""),
    orderNumberInternal: get(data, "orderNumberInternal", ""),
    updatedOrderNumberAt: get(data, "updatedOrderNumberAt", null),
    FBADate: get(data, "FBADate", null),
    plan: get(data, "plan.id", null),
    pointOfSale: get(data, "pointOfSale", ""),
    returnDate: get(data, "returnDate", null),
    SIMExpiryDate: get(data, "SIMExpiryDate", null),
    scheduledAt: get(data, "scheduledAt", null),
    scheduledBy: get(data, "scheduledBy", null),
    status: get(data, "status", ""),
    updatedAt: get(data, "updatedAt", null),
    updatedBy: get(data, "updatedBy", null),
    wholesalerId: get(data, "wholesalerId", "")
  };
}

export function getCardListState(data: DocumentData | undefined | null): CardList {
  const activationDate = get(data, "activationDate", null);
  const returnDate = get(data, "returnDate", null);
  const updatedAt = get(data, "updatedAt", null);
  const FBADate = get(data, "FBADate", null);
  return {
    ICCID: get(data, "ICCID", null),
    pointOfSale: get(data, "pointOfSale", null),
    onlyManualActivation: get(data, "onlyManualActivation", false),
    orderNumberCustomer: get(data, "orderNumberCustomer", null),
    orderNumberInternal: get(data, "orderNumberInternal", null),
    annotation: get(data, "annotation", ""),
    IMEI: get(data, "imei", null),
    puk: get(data, "puk", null),
    activationDate: activationDate ? dateFromTimestamp(activationDate) : null,
    done: get(data, "done", false),
    returnDate: returnDate ? dateFromTimestamp(returnDate) : null,
    MSISDN: get(data, "MSISDN", null),
    customerFullNameActivation: get(data, "customerFullNameActivation", null),
    customerFullNameBilling: get(data, "customerFullNameBilling", null),
    plan: get(data, "plan", null),
    status: get(data, "status", null),
    wholesalerId: get(data, "wholesalerId", null),
    updatedAt: updatedAt ? dateFromTimestamp(updatedAt) : null,
    FBADate: FBADate ? dateFromTimestamp(FBADate) : null
  };
}

function getCardFromSnapshot(snapshot: DocumentSnapshot): Card {
  const data = snapshot.data() || {};
  if (snapshot.exists) {
    return getCardDefaultState({ id: snapshot.id, ...data });
  }
  return getCardDefaultState(null);
}

export async function list(opts?: CardListFilters): Promise<CardList[]> {
  console.log("list", opts);

  try {
    const whereTypeDate = opts?.filters?.filterDateType || "";

    console.log("whereTypeDate", whereTypeDate);

    let snapshotNext;
    if (opts?.lastItem) {
      const refNext = getDocRef("cards", opts.lastItem);
      snapshotNext = await refNext.get();
    }

    let filters: Query = getColRef("cards");

    filters = filters.orderBy(whereTypeDate, "desc");
    filters = filters.startAfter(snapshotNext ? snapshotNext : "");
    filters = filters.limit(opts?.itemsLimit ? opts?.itemsLimit : 50);

    if (opts?.filters?.status !== "all") {
      if (opts?.filters?.status === "activated-api") {
        filters = filters.where("status", "==", "activated");
      } else if (opts?.filters?.status === "activated-manually") {
        console.log("activated-manually");
        filters = filters.where("done", "==", true);
      } else {
        filters = filters.where("status", "==", opts?.filters?.status);
      }
    }

    if (opts?.filters?.fromDate !== null) {
      filters = filters.where(whereTypeDate, ">=", moment(opts?.filters?.fromDate).startOf("day").toDate());

      if (opts?.filters?.toDate !== undefined) {
        filters = filters.where(
          whereTypeDate,
          "<=",
          moment(opts?.filters?.toDate === null ? undefined : opts?.filters?.toDate)
            .endOf("day")
            .toDate()
        );
        filters.limit(9999999);
      }
    }
    const snapshot = await filters.get();

    if (snapshot.empty) {
      return [];
    }

    return snapshot.docs.map((snap) => getCardListState(snap.data()));
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function loadCard(id: string): Promise<Card> {
  const ref = getDocRef("cards", id);
  const snapshot = await ref.get();
  const data = getCardFromSnapshot(snapshot);
  const cardFirestoreDocument = snapshot.data() || {};
  if (cardFirestoreDocument.plan) {
    data.plan = await showPlan(cardFirestoreDocument.plan);
  }
  return data;
}

// BASIC EXAMPLE
function validateCardData(data: Card): void {
  const error = new CustomError("Invalid card data", "form-inputs-error");

  if (!data.ICCID) {
    error.setError("ICCID", "ICCID is required");
  }
  if (data.ICCID && !checkICCID(data.ICCID)) {
    error.setError("ICCID", "ICCID is invalid");
  }
  if (!data.wholesalerId) {
    error.setError("wholesalerId", "ICCID is required");
  }
  if (!data.SIMExpiryDate) {
    error.setError("SIMExpiryDate", "SIM expiration date is required");
  }

  // email is not required but has to be valid.
  if (data.email && !emailIsValid(data.email)) {
    error.setError("email", "Email is invalid");
  }

  if (error.length) {
    throw error;
  }
}

export async function createCard(data: Card): Promise<Card> {
  validateCardData(data);

  const createData = {
    ...data,
    createdAt: new Date(),
    createdBy: requireUser().displayName,
    status: "stock"
  };

  const docData: CardFirestoreDocument = getCardFirestoreDocument(createData);
  const ref = getColRef("cards").doc(docData.ICCID);
  await ref.set(docData);

  return { id: ref.id, ...docData, plan: get(data, "plan", null) };
}

export async function updateCard(data: Card): Promise<Card> {
  validateCardData(data);
  const updateData = {
    ...data,
    updatedAt: new Date(),
    updatedBy: requireUser().displayName,
    updatedOrderNumberAt:
      data.orderNumberInternal !== "" && data.status === "stock" ? new Date() : data.updatedOrderNumberAt,
    status: data.orderNumberInternal !== "" && data.status === "stock" ? "updated" : data.status
  };

  const docData: CardFirestoreDocument = getCardFirestoreDocument(updateData);
  const ref = getDocRef("cards", data.id);
  await ref.update(docData);

  return { id: data.id, ...docData, plan: data.plan };
}

export async function deleteCard(id: string): Promise<void> {
  const card = getDocRef("cards", id);
  const snapshot = (await card.get()).data();
  if (snapshot?.task) {
    await getDocRef("tasks", snapshot.task).delete();
  }
  await card.delete();
}

// activationRequiredFields: ["plan", "email"],
function validateActivationRequiredData(data: Card): void {
  if (data.status === "failed") {
    throw new CustomError(SIM_SCHEDULE_FAIL, "invalid-card-data");
  }

  const error = new CustomError("Complete the required fields for activation", "form-inputs-error");

  if (!data.plan) {
    error.setError("plan", "Plan is required");
  }
  if (!data.email) {
    error.setError("email", "Email is required");
  }
  if (data.email && !emailIsValid(data.email)) {
    error.setError("email", "Email is invalid");
  }

  if (error.length) {
    throw error;
  }
}

export async function activateSIM(data: Card): Promise<string> {
  validateActivationRequiredData(data);
  try {
    const card = await updateCard(data);
    card.updatedAt = new Date();
    const response = await getFunctions().httpsCallable("activation")({ cardId: card.ICCID });
    const { errorCode, errorDesc, MSISDN } = response.data;
    if (errorCode !== "0" && errorCode !== "E0000") {
      throw new Error(`${errorCode} ${errorDesc}`);
    }
    return MSISDN;
  } catch (error) {
    throw new Error(SIM_ACTIVATION_FAIL);
  }
}

// scheduleRequiredFields: ["plan", "activationDate", "returnDate", "email"],
function validateScheduleData(data: Card): void {
  if (data.status === "failed") {
    throw new CustomError(SIM_SCHEDULE_FAIL, "invalid-card-data");
  }

  const error = new CustomError("Invalid card data", "form-inputs-error");

  if (!data.plan) {
    error.setError("plan", "Plan is required");
  }
  if (!data.activationDate) {
    error.setError("activationDate", "Activation date is required");
  }
  if (!data.returnDate) {
    error.setError("returnDate", "Return date is required");
  }
  if (!data.email) {
    error.setError("email", "Email is required");
  }
  if (data.email && !emailIsValid(data.email)) {
    error.setError("email", "Email is invalid");
  }

  if (error.length) {
    throw error;
  }
}

export async function scheduleActivation(data: Card): Promise<void> {
  validateScheduleData(data);
  const card = await updateCard({ ...data, updatedAt: new Date() });
  await getFunctions().httpsCallable("scheduleActivation")({
    order: card.orderNumberInternal.substr(card.orderNumberInternal.length - 5),
    iccid: card.ICCID,
    username: card.customerFullNameBilling,
    email: card.email,
    activationDate: card.activationDate?.toISOString(),
    returnDate: card.returnDate?.toISOString(),
    reschedule: true,
    scheduledAt: new Date(),
    scheduledBy: requireUser().displayName,
    updatedBy: requireUser().displayName
  });
}

export async function sendActivationEmail(data: Card): Promise<void> {
  if (!data.MSISDN) {
    throw new Error("Ensure you already activate this Card before send an activation email");
  }
  const cardData = await updateCard({ ...data, updatedAt: new Date(), updatedBy: getCurrentUser()?.displayName });

  await getFunctions().httpsCallable("sendEmailActivationCF")({
    iccid: cardData.ICCID
  });
}

export async function exportCardsFromBD(): Promise<void> {
  const res = await getFunctions().httpsCallable("exportCollectionCF")({ collection: "cards" });
  base64ToXLSX(res.data);
}
