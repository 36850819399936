import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Cards from "./containers/Cards";
import Card from "./containers/Card";
import Users from "./containers/Users";
import User from "./containers/User";
import Login from "./containers/Login";
import Activation from "./containers/Activation";
import BatchSIMCreation from "./containers/BatchSIMCreation";
import Plans from "./containers/Plans";
import Plan from "./containers/Plan";
import Settings from "./containers/Settings";
import "./utils/i18n";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/admin/batch" component={BatchSIMCreation} />
          <Route path="/admin/login" component={Login} />
          <Route path="/admin/cards/new" component={Card} />
          <Route path="/admin/cards/:id" component={Card} />
          <Route path="/admin/cards" component={Cards} />
          <Route path="/admin/users/new" component={User} />
          <Route path="/admin/users/:id" component={User} />
          <Route path="/admin/users" component={Users} />
          <Route path="/admin/plans/new" component={Plan} />
          <Route path="/admin/plans/:id" component={Plan} />
          <Route path="/admin/plans" component={Plans} />
          <Route path="/admin/settings" component={Settings} />
          <Route path="/admin" component={Cards} />
          <Route path="/" component={Activation} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
