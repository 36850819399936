import React from "react";
import { ActivationAttempt } from "../../utils/types";
import styles from "./styles.module.css";
import { formatDate } from "../../utils/time";

interface Params {
  data: ActivationAttempt[] | null | undefined;
}

const ActivationAttempts: React.FC<Params> = ({ data }) => {
  return (
    <>
      <div className={styles.container}>
        <h3>Activation Attempts</h3>
        <div className={styles.listHeader}>
          <div>Attempt At</div>
          <div>Status</div>
          <div>Description</div>
        </div>
        <>
          {data?.map((attempt, i) => (
            <div className={styles.listRow} key={i}>
              <div>{formatDate(attempt.attemptAt, { withTime: true })}</div>
              <div>{attempt.status}</div>
              <div>{attempt.description ? attempt.description : ""}</div>
            </div>
          ))}
        </>
      </div>
    </>
  );
};

export default ActivationAttempts;
