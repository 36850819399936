import React from "react";
import { Link } from "react-router-dom";
import { orderBy, uniqWith, isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faAngleDown, faAngleUp, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import SectionLayout from "../../components/SectionLayout";
import SectionLoader from "../../components/SectionLoader";
import AuthHoc from "../../components/AuthHoc";
import { listPlans } from "../../services/plans";
import { getDataInFinalScroll } from "../../utils/validations";
import ListItem from "./ListItem";
import styles from "./styles.module.css";
import { Plan } from "../../utils/types";

export interface State {
  status: "initialLoader" | "loading" | "loaded" | "failure";
  sortColumnName: string;
  sortColumnDir: boolean | "asc" | "desc";
  itemsLimit: number;
  lastItem: string;
  dataLastLength: number;
  data: Plan[];
}

class Plans extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      status: "initialLoader",
      sortColumnName: "",
      sortColumnDir: "asc",
      itemsLimit: 30,
      lastItem: "",
      dataLastLength: 0,
      data: []
    };
  }

  componentDidMount(): void {
    this.getPlans();
  }

  getPlans = async (): Promise<void> => {
    const { data, status, itemsLimit, lastItem } = this.state;
    this.setState({
      status: status === "initialLoader" ? "initialLoader" : "loading"
    });

    const nextData = await listPlans({ itemsLimit, lastItem });

    this.setState({
      data: uniqWith([...data, ...nextData], isEqual),
      lastItem: nextData.slice(-1)[0]?.id,
      dataLastLength: data.length,
      status: "loaded"
    });
  };

  handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    if (getDataInFinalScroll(e, this.state.data.length, this.state.dataLastLength)) {
      this.getPlans();
    }
  };

  sortColumn = (sortColumnName: string, sortColumnDir: boolean | "asc" | "desc"): void => {
    this.setState({
      sortColumnDir: sortColumnDir === "asc" ? "desc" : "asc",
      sortColumnName,
      data: orderBy(this.state.data, [sortColumnName], [sortColumnDir])
    });
  };

  render(): JSX.Element {
    const { data, status, sortColumnName, sortColumnDir } = this.state;

    const faAngleDirection = (sortColumnDir: boolean | "asc" | "desc"): IconDefinition =>
      sortColumnDir === "asc" ? faAngleUp : faAngleDown;

    return (
      <SectionLayout label="Plans">
        <div className={styles.list}>
          <div className={styles.header}>
            <Link to={`/admin/plans/new`}>
              <button className={styles.button}>Add Plan</button>
            </Link>
          </div>

          <div className={styles.listHeader}>
            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("provider", sortColumnDir)}>
              Provider {sortColumnName === "provider" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>
            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("bundleCode", sortColumnDir)}>
              Bundle code{" "}
              {sortColumnName === "bundleCode" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>
            <div
              className={styles.listHeaderSort}
              onClick={(): void => this.sortColumn("internationalTopUpCode", sortColumnDir)}
            >
              International top up code{" "}
              {sortColumnName === "internationalTopUpCode" ? (
                <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} />
              ) : (
                ""
              )}
            </div>
            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("months", sortColumnDir)}>
              Months {sortColumnName === "months" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>
            <div>Description</div>
            <div className={styles.listHeaderSort} onClick={(): void => this.sortColumn("planAmount", sortColumnDir)}>
              Plan amount{" "}
              {sortColumnName === "planAmount" ? <FontAwesomeIcon icon={faAngleDirection(sortColumnDir)} /> : ""}
            </div>
          </div>
          <div className={styles.overflow_list} onScroll={(e): void => this.handleScroll(e)}>
            {status === "initialLoader" ? (
              <SectionLoader />
            ) : (
              <>
                {data.map((plan, i) => (
                  <ListItem data={plan} key={i} />
                ))}

                <div className={styles.footer}>
                  {["loading"].indexOf(status) !== -1 ? <FontAwesomeIcon icon={faSpinner} spin /> : ""}
                </div>
              </>
            )}
          </div>
        </div>
      </SectionLayout>
    );
  }
}

export default AuthHoc(Plans);
