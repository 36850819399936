import { Timestamp } from "@firebase/firestore-types";
import moment from "moment";

export function dateFromTimestamp(timestamp: Timestamp): Date {
  if (timestamp) {
    return timestamp.toDate();
  }
  return new Date();
}

interface DateTimeFormatOptions {
  withTime?: true | false;
  withTimezone?: true | false;
}
export function formatDate(date?: Date | null, params?: DateTimeFormatOptions): string {
  return date
    ? moment(date).format(`DD.MM.YYYY ${params?.withTime ? "HH:mm" : ""} ${params?.withTimezone ? "Z" : ""}`)
    : "";
}

export const dateFromTimestampWithFormat = (timestamp: Timestamp): string => formatDate(dateFromTimestamp(timestamp));

export const timestampSecondsToDate = (secondsUnix: number): Date =>
  secondsUnix ? moment.unix(secondsUnix).toDate() : new Date();
