import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.css";
import { requireUser } from "../../utils/firebase";

interface Props {
  usermenuOpen: boolean;
  usermenuToggle: (status: boolean) => void;
  onLogout: () => void;
}

const Header: React.FC<Props> = ({ usermenuOpen, usermenuToggle, onLogout }) => {
  return (
    <div className={styles.header}>
      <div className={styles.usermenuContainer}>
        <button
          className={styles.button}
          onClick={(e): void => {
            e.stopPropagation();
            usermenuToggle(!usermenuOpen);
          }}
        >
          <span className={styles.user_header_name}>{requireUser().displayName}</span>
          <FontAwesomeIcon icon={faUser} />
        </button>
        <div className={styles.usermenu} style={{ display: usermenuOpen ? "block" : "none" }}>
          <button onClick={onLogout}>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
