import React from "react";
import Modal from "../Modal";
import styles from "./styles.module.css";

interface Props {
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick: () => void;
}

export default class ConfirmDeleteModal extends React.Component<Props> {
  render(): JSX.Element {
    const { onClose, onClick, open } = this.props;

    return (
      <Modal onClose={onClose} open={open}>
        <div className={styles.title}>Are you sure you wish to delete this item?</div>
        <div className={styles.buttons}>
          <button className={styles.button}>Cancel</button>
          <button className={styles.delete_button} onClick={onClick}>
            Delete
          </button>
        </div>
      </Modal>
    );
  }
}
