import React from "react";
import Text from "../../components/Input/Text";
import Button from "../../components/Button";
import styles from "./styles.module.css";
import { auth } from "../../utils/firebase";
import Logo from "../../assets/logo.png";

interface Props {
  history: {
    push: (path: string) => never;
  };
}
interface State {
  process: boolean;
  status: string;
  message: string;
  email: string;
  password: string;
}

export default class LoginContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      process: false,
      status: "",
      message: "",
      email: "",
      password: ""
    };
  }
  submit = async (): Promise<void> => {
    const { email, password } = this.state;
    this.setState({ process: true, status: "", message: "", email: "", password: "" }, async () => {
      try {
        await auth?.signInWithEmailAndPassword(email, password);
        this.props.history.push("/admin");
      } catch (error) {
        this.setState({
          process: false,
          status: "failure",
          message: error.message
        });
      }
    });
  };
  render(): JSX.Element {
    const { status, message, email, password } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.logo}>
            <img src={Logo} alt="" />
          </div>
          <Text
            value={email}
            placeholder="username"
            onChange={(value): void => this.setState({ email: value })}
            showError={false}
          />
          <Text
            id="password"
            value={password}
            password
            placeholder="password"
            onChange={(value): void => this.setState({ password: value })}
            showError={false}
          />
          <br />
          <Button label="Login" type="button" onClick={this.submit} />
          <div className={status === "success" ? styles.success : styles.error}>{message}</div>
        </div>
      </div>
    );
  }
}
