import React, { useState } from "react";
import SideNavBar from "../SideNavBar";
import { faSimCard, faUserAstronaut, faEraser, faCogs } from "@fortawesome/free-solid-svg-icons";
import { auth } from "../../utils/firebase";
import Header from "../Header";
import styles from "./styles.module.css";

const Layout: React.FC<{}> = ({ children }) => {
  const [usermenuOpen, usermenuToggle] = useState(false);
  return (
    <div className={styles.container} onClick={(): void => usermenuToggle(false)}>
      <SideNavBar
        title="SEPITO"
        options={[
          {
            id: "cards",
            label: "Cards",
            icon: faSimCard,
            path: "/admin/cards"
          },
          {
            id: "plans",
            label: "Plans",
            icon: faEraser,
            path: "/admin/plans"
          },
          {
            id: "users",
            label: "Users",
            icon: faUserAstronaut,
            path: "/admin/users"
          },
          {
            id: "settings",
            label: "Settings",
            icon: faCogs,
            path: "/admin/settings"
          }
        ]}
      />
      <div className={styles.content}>
        <Header
          usermenuOpen={usermenuOpen}
          usermenuToggle={usermenuToggle}
          onLogout={async (): Promise<void> => {
            await auth?.signOut();
          }}
        />
        {children}
      </div>
    </div>
  );
};

export default Layout;
