import React from "react";
import styles from "./styles.module.css";

interface Props {
  message?: string;
}

const NotFound: React.FC<Props> = ({ message }) => {
  return (
    <div className={styles.not_found}>
      <span> {message ? message : "Not Found"}</span>
    </div>
  );
};

export default NotFound;
