import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.css";

export interface Props {
  type?: "button" | "submit" | "reset" | undefined;
  label: string;
  style?: string | number;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const cx = classnames.bind(styles);

const Button: React.FC<Props> = ({ type, label, style, loading, onClick }) => {
  return (
    <button type={type || "button"} className={cx([styles.button, style])} onClick={onClick}>
      {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <span>{label}</span>}
    </button>
  );
};

export default Button;
