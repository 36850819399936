import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import styles from "./styles.module.css";

interface Props {
  id?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  password?: boolean;
  onChange?: (value: string, id?: string) => void;
  onBlur?: (value: string, id?: string) => void;
  onHelp?: () => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  disabled?: boolean;
  readOnly?: boolean;
  inputClassname?: string;
  containerStyle?: string;
  showLabel?: boolean;
  showError?: boolean;
  error?: string;
}

const InputText: React.FC<Props> = (props) => {
  const {
    id,
    value,
    label,
    onChange,
    onBlur,
    placeholder,
    password,
    onHelp,
    disabled,
    readOnly,
    onClick,
    inputClassname,
    containerStyle,
    showError,
    showLabel,
    error
  } = props;
  const change = (e: React.ChangeEvent<HTMLInputElement>): void => onChange?.(e.target.value, id);
  const blur = (e: React.FocusEvent<HTMLInputElement>): void => onBlur?.(e.target.value, id);

  return (
    <div className={`${styles.inputContainer} ${containerStyle || ""}`}>
      {showLabel ? (
        <div className={styles.labelContainer}>
          <label className={styles.inputLabel} htmlFor={id}>
            {label}
          </label>
          {onHelp ? (
            <button onClick={onHelp} className={styles.helpButton} type="button">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </button>
          ) : null}
        </div>
      ) : null}
      <input
        id={id}
        autoComplete={id === "order" ? "nope" : undefined}
        className={`${styles.input} ${error ? styles.inputError : ""}  ${inputClassname ? " " + inputClassname : ""}`}
        value={value}
        type={password ? "password" : "text"}
        onChange={change}
        onBlur={blur}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onClick}
      />
      {showError ? (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{error}</span>
        </div>
      ) : null}
    </div>
  );
};

InputText.defaultProps = {
  showLabel: true,
  showError: true
};

export default InputText;
